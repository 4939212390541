import { useState, useEffect } from 'react';
import { useForm, Controller } from "react-hook-form";
import { useQueryClient } from '@tanstack/react-query';
import {
  Box,
  Grid,
  FormControl,
  TextField,
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  MenuItem,
} from '@mui/material';
import { carregaEstoques, carregaPontosEntrega, manipulaEquipamento } from '../configs/functions';
import { fillInputErrors, getFormErrorMessage } from '../../../../../configs/functions';
import { useCommonItems } from '../../../../../contexts/CommonItensProvider';
import { CommonDialogActionsForm } from '../../../../common/CommonDialogActionsForm';
import { TooltipSelectDisabled } from '../../../../common/TooltipSelectDisabled';

export function DeskEquipmentForm({ open, setOpen, equipamento, empresas, queryKey }) {
  const queryClient = useQueryClient();
  const { exibirDialog, handleCloseDialog, exibirAlerta } = useCommonItems();

  const isEditAction = !!equipamento;

  const [estoques, setEstoques] = useState([]);
  const [pontosEntrega, setPontosEntrega] = useState([]);
  const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [hasStockModule, setHasStockModule] = useState(false);

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    clearErrors,
    reset,
    control,
    formState: { dirtyFields, errors },
  } = useForm();

  const loadPontosEntrega = async (empresaId) => {
    try {
      const filterOnlyActives = {
        status: 1,
        id_empresa: empresaId
      };
      const response = await carregaPontosEntrega(filterOnlyActives);
      setPontosEntrega(response.data.data);
    } catch (error) {
      exibirAlerta('Erro ao carregar os Pontos de Entrega', '', 'error');
    }
  }

  const loadEstoque = async (empresaId) => {
    try {
      const filterOnlyActives = {
        status: 1,
        id_empresas: [empresaId]
      };
      const response = await carregaEstoques(filterOnlyActives);
      const allStocks = response.data.data;
      // remover os que tenham classificacao como 'vending_machine'
      const filteredStocks = allStocks.filter(stock => stock.classificacao[0] !== 'vending_machine');
      setEstoques(filteredStocks);
      setHasStockModule(true);
    } catch (error) {
      const messageError = error.response ? error.response.data.error : 'Erro ao carregar os Estoque';
      setHasStockModule(false);
      // se o erro for diferente de 'não tem acesso a esse módulo', exibe o alerta
      if (!messageError.includes('não tem acesso a esse módulo')) {
        exibirAlerta('Erro ao carregar os Estoque', '', 'error');
      }
    }
  }

  const onSubmit = async (data) => {
    let formDataToSend = {};
    setIsLoading(true);
    exibirDialog('Salvando. Aguarde...');

    if (isEditAction) {
      const dirtyFieldKeys = Object.keys(dirtyFields);

      const dirtyData = dirtyFieldKeys.reduce((acc, key) => {
        if (data.hasOwnProperty(key)) {
          acc[key] = data[key];
        }
        return acc;
      }, {});

      if (Object.keys(dirtyData).length === 0) {
        exibirAlerta('Atenção', 'Nenhum dado foi alterado.', 'warning');
        handleCloseDialog();
        setOpen(false);
        setIsLoading(false);
        return;
      }

      formDataToSend = dirtyData;
      formDataToSend.id = equipamento.id;
    } else {
      delete data.id;

      if (!data.tag_identificacao) {
        delete data.tag_identificacao;
      }

      formDataToSend = data;
    }

    // if !hasStockModule, remove id_estoque from formDataToSend
    if (!hasStockModule) {
      delete formDataToSend.id_estoque;
    }

    try {
      const res = await manipulaEquipamento(formDataToSend, isEditAction);
      exibirAlerta('Sucesso', res.data.message, 'success');
      setOpen(false);

      // quando backend passar a retornar objeto criado/editado, fazer as operacoes com o cache, ao inves de fazer o refetch
      // assim como foi feito no DocumentTypeForm.jsx

      // enquanto nao tem o obj criado/editado, invalidar a query para atualizar a lista
      queryClient.invalidateQueries({ queryKey });
    } catch (error) {
      let errorMessage = "Erro ao cadastrar Balcão.";
      if (error.response) {
        errorMessage = error.response.data.error
        fillInputErrors(error, setError);
      }
      exibirAlerta('Erro', errorMessage, 'error');
    } finally {
      setIsLoading(false);
      handleCloseDialog();
    }
  };

  useEffect(() => {
    // preenche o formulário ao abrir p/ edicao
    if (open) {
      const defaultValues = {
        id: equipamento ? equipamento.id : '',
        nome: equipamento ? equipamento.nome : '',
        tag_identificacao: equipamento ? equipamento.tag_identificacao : '',
        status: equipamento ? equipamento.status : 1,
        tipo: 'balcao',
        tipo_contrato: 'locacao',
        descricao: equipamento ? equipamento.descricao : '',
        id_empresa: equipamento ? equipamento.empresa.id : '',
        id_ponto_entrega: equipamento ? equipamento.pontoEntrega.id : '',
        id_estoque: equipamento ? equipamento.estoque.id : '',
      };

      if (equipamento) {
        setSelectedEmpresaId(equipamento.empresa.id);
      }
      reset(defaultValues);
    } else {
      reset();
      setSelectedEmpresaId(null);
      setHasStockModule(false);
    }
  }, [open, reset]);

  useEffect(() => {
    if (open && selectedEmpresaId) {
      loadPontosEntrega(selectedEmpresaId);
      loadEstoque(selectedEmpresaId);
    }
  }, [selectedEmpresaId, open]);

  return (
    <Dialog open={open} fullWidth maxWidth={"md"}>
      <form onSubmit={handleSubmit(onSubmit)}>
        {isEditAction
          ? <DialogTitle>Editando Balcão <strong>{equipamento.nome}</strong></DialogTitle>
          : <DialogTitle>Cadastrar novo Balcão</DialogTitle>
        }
        <DialogContent>
          <DialogContentText sx={{ mb: 2 }}>
            {isEditAction ? 'Preencha corretamente os dados.' : 'Preencha corretamente os dados abaixo para cadastrar um novo Balcão.'}
          </DialogContentText>

          <Box sx={{ p: 2, backgroundColor: "#fafafa" }} >
            <FormControl variant="standard" fullWidth >
              <Grid
                container
                direction="row"
                justifyContent="flex-start"
                alignItems="flex-start"
                spacing={2}
              >
                <Grid item xs={12} md={8}>
                  <TextField
                    autoFocus
                    InputLabelProps={{ shrink: true }}
                    margin='dense'
                    label='Nome'
                    placeholder='Digite o nome do Balcão'
                    name='nome'
                    fullWidth
                    {...register("nome", { required: true })}
                    error={!!errors.nome}
                    helperText={getFormErrorMessage(errors, 'nome')}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12} md={4}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    margin='dense'
                    label='Código de Identificação'
                    placeholder='Digite o código de identificação'
                    name='tag_identificacao'
                    fullWidth
                    {...register("tag_identificacao")}
                    error={!!errors.tag_identificacao}
                    helperText={!!errors.tag_identificacao ? getFormErrorMessage(errors, 'tag_identificacao') : 'O código será criado automaticamente se não preenchido.'}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={12}>
                  <TextField
                    InputLabelProps={{ shrink: true }}
                    margin='dense'
                    label='Descrição'
                    placeholder='Digite a Descrição'
                    name='descricao'
                    fullWidth
                    {...register("descricao")}
                    variant="outlined"
                    multiline
                    rows={3}
                    error={!!errors.descricao}
                    helperText={getFormErrorMessage(errors, 'descricao')}
                  />
                </Grid>

                {empresas &&
                  <Grid container item xs={12} spacing={2}>
                    <Grid item xs={12} md={4}>
                      <Controller
                        name='id_empresa'
                        control={control}
                        rules={{ required: true }}
                        defaultValue={equipamento ? equipamento.empresa.id : ""}
                        render={({ field: { ref, onChange, ...field } }) => (
                          <TextField
                            {...field}
                            select
                            fullWidth
                            sx={{ mt: 1 }}
                            label="Empresa"
                            defaultValue={equipamento ? equipamento.empresa.id : ""}
                            onChange={(e) => {
                              setSelectedEmpresaId(e.target.value);
                              setValue('id_empresa', e.target.value, { shouldDirty: true })
                              setValue('id_ponto_entrega', '', { shouldDirty: true })
                              setValue('id_estoque', '', { shouldDirty: true })
                              if (e.target.value) {
                                clearErrors('id_empresa');
                              }
                            }}
                            error={!!errors.id_empresa}
                            helperText={getFormErrorMessage(errors, 'id_empresa')}
                          >
                            {empresas.map((option) => (
                              <MenuItem key={option.id} value={option.id}>
                                {option.nomeFantasia}
                              </MenuItem>
                            ))}
                          </TextField>
                        )}
                      />
                    </Grid>

                    {pontosEntrega &&
                      <Grid item xs={12} md={4}>
                        <TooltipSelectDisabled isDisabled={selectedEmpresaId}>
                          <Controller
                            name='id_ponto_entrega'
                            control={control}
                            rules={{ required: true }}
                            defaultValue={equipamento ? equipamento.pontoEntrega.id : ""}
                            render={({ field: { ref, onChange, ...field } }) => (
                              <TextField
                                {...field}
                                select
                                fullWidth
                                sx={{ mt: 1 }}
                                label="Ponto de Entrega"
                                disabled={!selectedEmpresaId}
                                onChange={(e) => {
                                  setValue('id_ponto_entrega', e.target.value, { shouldDirty: true })
                                  if (e.target.value) {
                                    clearErrors('id_ponto_entrega');
                                  }
                                }}
                                error={!!errors.id_ponto_entrega}
                                helperText={getFormErrorMessage(errors, 'id_ponto_entrega')}
                              >
                                {pontosEntrega.length > 0 ? (
                                  pontosEntrega.map((option) => (
                                    <MenuItem key={option.id} value={option.id}>
                                      {option.nome}
                                    </MenuItem>
                                  ))
                                ) : (
                                  <MenuItem disabled>Nenhum disponível</MenuItem>
                                )}
                              </TextField>
                            )}
                          />
                        </TooltipSelectDisabled>
                      </Grid>
                    }

                    {(estoques && hasStockModule) &&
                      <Grid item xs={12} md={4}>
                        <TooltipSelectDisabled isDisabled={selectedEmpresaId}>
                          <Controller
                            name='id_estoque'
                            control={control}
                            rules={{ required: hasStockModule }}
                            defaultValue={equipamento ? equipamento.estoque.id : ""}
                            render={({ field: { ref, onChange, ...field } }) => (
                              <TextField
                                {...field}
                                select
                                fullWidth
                                sx={{ mt: 1 }}
                                label="Estoque"
                                disabled={!selectedEmpresaId}
                                onChange={(e) => {
                                  setValue('id_estoque', e.target.value, { shouldDirty: true })
                                  if (e.target.value) {
                                    clearErrors('id_estoque');
                                  }
                                }}
                                error={!!errors.id_estoque}
                                helperText={getFormErrorMessage(errors, 'id_estoque')}
                              >
                                {estoques.length > 0 ? (
                                  estoques.map((option) => (
                                    <MenuItem key={option.estoques_id[0]} value={option.estoques_id[0]}>
                                      {option.nome}
                                    </MenuItem>
                                  ))
                                ) : (
                                  <MenuItem disabled>Nenhum disponível</MenuItem>
                                )}
                              </TextField>
                            )}
                          />
                        </TooltipSelectDisabled>
                      </Grid>
                    }
                  </Grid>
                }
              </Grid>
            </FormControl>
          </Box>
        </DialogContent>

        <CommonDialogActionsForm errors={errors} isLoading={isLoading} setOpen={setOpen} />
      </form>
    </Dialog>
  );
}