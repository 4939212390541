import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet-async';
import { Link, useSearchParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import {
  Box,
  Breadcrumbs,
  Button,
  Collapse,
  Grid,
  IconButton,
  Paper,
  Skeleton,
  Stack,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Typography,
} from '@mui/material';
import { ArrowOutward, Home, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import { redirectUnauthorizedUser } from '../../../configs/functions';
import { RadarIcon } from '../../common/Icons';
import PageTitle from '../../common/PageTitle';
import { getContratosAndCompaniesInfo } from '../management/contract/functions';
import { useCommonItems } from '../../../contexts/CommonItensProvider';
import { LoadingTableRow } from '../../common/LoadingTableRow';
import { EmptyTableRow } from '../../common/EmptyTableRow';
import { CompaniesContractTable } from './CompaniesContractTable';
import { Pagination } from '../../common/Pagination';
import { useAppContext } from '../../../contexts/AppContext';
import { IndicatorsTableCell } from './IndicatorsTableCell';
import { LinearProgressContractInfo } from './components/LinearProgressContractInfo';
import LinkNavegacao from '../../common/Link';
import { FiltersButton } from '../../common/FiltersButton';
import { ContractsTableFilter } from './components/ContractsTableFilter';
import { TooltipSelectDisabled } from '../../common/TooltipSelectDisabled';

const ContractTableRow = ({ contrato }) => {
  const [openAbilities, setOpenAbilities] = useState(false);

  const equipamentos = contrato?.empresas?.reduce((acc, empresa) => {
    return acc.concat(empresa.equipamentos);
  }, []);

  const alertasErrosDeEntrega = contrato?.empresas?.reduce((acc, empresa) => {
    const alertas = empresa.alertas.filter((alerta) => alerta.alarme.id === 15);
    return acc.concat(alertas);
  }, []);

  const totalEquipDesconectadosContrato = contrato?.empresas?.reduce((acc, empresa) => acc + empresa.summary.totalEquipDesconectados, 0);
  const totalEquipEstoqueBaixoContrato = contrato?.empresas?.reduce((acc, empresa) => acc + empresa.summary.totalEquipEstoqueBaixo, 0);
  const totalEpisVencidosContrato = contrato?.empresas?.reduce((acc, empresa) => acc + empresa.summary.totalEpisVencidos, 0);
  const totalCAsVencidosContrato = contrato?.empresas?.reduce((acc, empresa) => acc + empresa.summary.totalCAsVencidos, 0);
  const totalCAsVencerContrato = contrato?.empresas?.reduce((acc, empresa) => acc + empresa.summary.totalCAsVencer, 0);
  const totalAdmins = contrato?.empresas?.reduce((acc, empresa) => acc + empresa.totalAdmins, 0);
  const totalColaboradores = contrato?.empresas?.reduce((acc, empresa) => acc + empresa.totalColaboradores, 0);

  return (
    <>
      <TableRow hover>
        <TableCell align="center" sx={{ maxWidth: 20 }}>
          {contrato?.empresas?.length > 0 && (
            <IconButton aria-label="Empresas" onClick={() => setOpenAbilities(!openAbilities)}>
              {openAbilities ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          )}
        </TableCell>

        <TableCell>
          {contrato.nome}
        </TableCell>

        <TableCell>
          <Stack direction="column" spacing={0.5} justifyContent='center' alignItems='center'>
            <Typography variant="body2" color="textSecondary">
              {totalAdmins || 0} de {contrato?.qtd_colaborador_admin || 100}
            </Typography>

            <LinearProgressContractInfo
              value={totalAdmins || 0}
              limit={contrato?.qtd_colaborador_admin || 100}
            />
          </Stack>
        </TableCell>

        <TableCell>
          <Stack direction="column" spacing={0.5} justifyContent='center' alignItems='center'>
            <Typography variant="body2" color="textSecondary">
              {totalColaboradores || 0} de {contrato?.qtd_colaboradores || 100}
            </Typography>

            <LinearProgressContractInfo
              value={totalColaboradores || 0}
              limit={contrato?.qtd_colaboradores || 100}
            />
          </Stack>
        </TableCell>

        <TableCell>
          {/* <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center ' }}>
            <ProgressCounter
              value={contrato?.empresas?.length || 0}
              limit={contrato?.qtd_empresas || 100}
            />
          </Box> */}

          <Stack direction="column" spacing={0.5} justifyContent='center' alignItems='center'>
            <Typography variant="body2" color="textSecondary">
              {contrato?.empresas?.length || 0} de {contrato?.qtd_empresas || 100}
            </Typography>

            <LinearProgressContractInfo
              value={contrato?.empresas?.length || 0}
              limit={contrato?.qtd_empresas || 100}
            />
          </Stack>
        </TableCell>

        <IndicatorsTableCell
          alertas={alertasErrosDeEntrega}
          equipamentos={equipamentos}
          totalEquipDesconectados={totalEquipDesconectadosContrato}
          totalEquipEstoqueBaixo={totalEquipEstoqueBaixoContrato}
          totalEpisVencidos={totalEpisVencidosContrato}
          totalCAsVencidos={totalCAsVencidosContrato}
          totalCAsVencer={totalCAsVencerContrato}
        />
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={openAbilities} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <CompaniesContractTable empresas={contrato?.empresas || []} />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export function AdminPanel() {
  const { exibirAlerta } = useCommonItems();
  const { dadosUsuario } = useAppContext();

  const [searchParams, setSearchParams] = useSearchParams();

  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [activeFilters, setActiveFilters] = useState(0);

  const [lastUpdated, setLastUpdated] = useState(null);

  // estados para controle de paginacao
  const page = parseInt(searchParams.get('page') || 1) - 1;
  const rowsPerPage = parseInt(searchParams.get('perPage') || 10);

  const queryFilters = {
    limit: rowsPerPage,
    offset: page * rowsPerPage,
    nome_contrato: searchParams.get('nome_contrato') || '',
    mode_equipamento: searchParams.get('mode_equipamento') || '',
  };

  const { data: contratoData, isLoading: isLoadingContrato, error: errorLoadContratoData, isSuccess, isRefetching } = useQuery({
    queryKey: ['listaContratosSuporte', queryFilters],
    queryFn: () => getContratosAndCompaniesInfo(queryFilters),
    refetchInterval: 1000 * 60 * 15, // 15 min
    refetchIntervalInBackground: true,
  });

  const contratos = contratoData?.data?.data || [];
  const numTotalItems = contratoData?.data?.numero_total || 0;
  const isLastPage = (page * rowsPerPage + rowsPerPage) >= numTotalItems;

  useEffect(() => {
    if (errorLoadContratoData) {
      exibirAlerta('Erro', 'Erro ao carregar as informações das empresas.', 'error');
    }
  }, [errorLoadContratoData, exibirAlerta])

  useEffect(() => {
    if (!dadosUsuario?.isSempher) {
      redirectUnauthorizedUser();
    }

    setSearchParams((state) => {
      state.set('page', String(page + 1));
      state.set('perPage', String(rowsPerPage));
      return state;
    });
  }, [dadosUsuario, page, rowsPerPage, setSearchParams]);

  useEffect(() => {
    const queryFilters = { ...Object.fromEntries(searchParams) }
    const tableFilters = { ...queryFilters };
    delete tableFilters.page;
    delete tableFilters.perPage;

    const activeFilters = Object.keys(tableFilters).length;
    setActiveFilters(activeFilters);
    setIsOpenFilters(activeFilters > 0);
  }, [searchParams]);

  useEffect(() => {
    if (isSuccess && !isRefetching) {
      const date = new Date();
      const formattedDate = date.toLocaleString('pt-BR');
      setLastUpdated(formattedDate);
    }
  }, [isSuccess, isRefetching]);

  return (
    <>
      <Helmet title="Painel de Monitoramento" defer={false} />

      <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={2}>
        <Grid item xs={12}>
          <Breadcrumbs>
            <LinkNavegacao to='/'><Home fontSize='small' /></LinkNavegacao>
            <Typography variant='span'>Gerenciamento Admin</Typography>
            <Typography variant='span'>Painel de Monitoramento</Typography>
          </Breadcrumbs>

          <PageTitle icon={<RadarIcon fontSize='large' />} title='Painel de Monitoramento' />

          <Typography p pb={0}>
            Monitore as empresas e acompanhe o status de suas Máquinas e Equipamentos
          </Typography>

          <Grid item xs={12}>
            <Stack direction='row' spacing={0.5} alignItems='center' p sx={{ pt: 0 }}>
              <Typography variant='caption' color='textSecondary'>Última atualização: </Typography>
              {isLoadingContrato ? (
                <Skeleton width={110} variant='text' />
              ) : (
                <Typography variant='caption' color='textSecondary'>
                  {lastUpdated}
                </Typography>
              )}
            </Stack>
          </Grid>
        </Grid>

        <Grid item xs={12}>
          <Stack direction={{ xs: 'column-reverse', md: 'row' }} spacing={2} justifyContent={'space-between'}>
            <FiltersButton
              isOpenFilters={isOpenFilters}
              setIsOpenFilters={setIsOpenFilters}
              activeFilters={activeFilters}
            />

            <TooltipSelectDisabled
              isDisabled={!isLastPage}
              text="Não ha novas páginas para abrir"
            >
              <Link
                to={`/admin/painel?page=${page + 2}&perPage=${searchParams.get('perPage')}`}
                target="_blank"
                rel="noopener noreferrer"
                style={{ pointerEvents: isLastPage ? 'none' : 'auto' }}
              >
                <Button variant="contained" startIcon={<ArrowOutward />} disabled={isLastPage}>
                  Abrir Nova Aba
                </Button>
              </Link>
            </TooltipSelectDisabled>
          </Stack>
        </Grid>

        <ContractsTableFilter
          isOpenFilters={isOpenFilters}
          isLoadingList={isLoadingContrato}
        />

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="Contratos">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Contrato</TableCell>
                  <TableCell>Usuários</TableCell>
                  <TableCell>Colaboradores</TableCell>
                  <TableCell>Empresas</TableCell>
                  <TableCell align='right'>Indicadores</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {isLoadingContrato
                  ? <LoadingTableRow />
                  : contratos && contratos.length > 0 ? (
                    contratos?.map((item) => (
                      <ContractTableRow key={item.id} contrato={item} />
                    ))
                  ) : <EmptyTableRow infoType='contrato' />
                }
              </TableBody>
            </Table>

            <Pagination
              page={page}
              rowsPerPage={rowsPerPage}
              numTotalItems={numTotalItems}
            />
          </TableContainer>
        </Grid>
      </Grid>
    </>
  );
}