import {
  Box,
  Dialog,
  IconButton,
  Stack,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Switch,
} from '@mui/material';
import { Close, CheckCircle, Error, Help } from '@mui/icons-material';

export function MachineStatusDialog({ equipamentos, open, setOpen }) {
  const getModeIcon = (mode) => {
    switch (mode) {
      case 'online':
        return <CheckCircle color="success" />;
      case 'offline':
        return <Error color="error" />;
      case 'unknown':
      default:
        return <Help color="action" />;
    }
  };

  return (
    <Dialog open={open} onClose={() => setOpen(false)} fullWidth maxWidth="md">
      <IconButton
        aria-label="close"
        onClick={() => setOpen(false)}
        sx={{
          position: 'absolute',
          right: 8,
          top: 8,
          color: (theme) => theme.palette.grey[500],
        }}
      >
        <Close />
      </IconButton>

      <Box sx={{ padding: 2 }}>
        <Typography variant="h6" mb={2} fontWeight="bold">Equipamentos:</Typography>

        {equipamentos.length > 0 ? (
          <TableContainer component={Paper}>
            <Table aria-label="equipamentos status table">
              <TableHead>
                <TableRow>
                  <TableCell>Id</TableCell>
                  <TableCell>Nome</TableCell>
                  <TableCell>Estado de Conexão</TableCell>
                  <TableCell>Status</TableCell>
                </TableRow>
              </TableHead>

              <TableBody>
                {equipamentos.map((equipamento) => (
                  <TableRow key={equipamento.id}>
                    <TableCell>{equipamento.id}</TableCell>
                    <TableCell>{equipamento.nome}</TableCell>
                    <TableCell>
                      <Stack direction="row" alignItems="center" spacing={1}>
                        {getModeIcon(equipamento.mode)}
                        <Typography variant="body2">{equipamento.mode}</Typography>
                      </Stack>
                    </TableCell>
                    <TableCell>
                      <Switch
                        checked={equipamento.status}
                        color="primary"
                        inputProps={{ 'aria-label': 'primary checkbox' }}
                        disabled={!equipamento.status}
                        onChange={() => { }}
                      />
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <Typography variant="body2" color="textSecondary">Nenhum equipamento disponível.</Typography>
        )}
      </Box>
    </Dialog>
  );
}
