import { useState, useEffect } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { Helmet } from 'react-helmet-async';
import { useSearchParams } from 'react-router-dom';
import {
  Grid,
  Breadcrumbs,
  Typography,
  Button,
  TableContainer,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
  Paper,
  Table,
  IconButton,
  Stack,
  Collapse,
  Box
} from '@mui/material';
import { Add, Home as HomeIcon, KeyboardArrowDown, KeyboardArrowUp } from '@mui/icons-material';
import PageTitle from '../../../common/PageTitle';
import LinkNavegacao from '../../../common/Link';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { CyberSecurityIcon, EditIcon, TrashIcon } from '../../../common/Icons';
import ConfirmDialog from '../../../common/ConfirmDialog';
import { checkPermissionsAndRedirectAdmin, hasPermission } from '../../../../configs/functions';
import { EmptyTableRow } from '../../../common/EmptyTableRow';
import { SkeletonTableRow } from '../../../common/SkeletonTableRow';
import { Pagination } from '../../../common/Pagination';
import { deletaHabilidade, listaHabilidades } from '../configs/functions';
import { AbilityForm } from './AbilityForm';
import { AbilityDependencies } from './AbilityDependencies';
import { AbilitiesTableFilter } from './AbilitiesTableFilter';
import { FiltersButton } from '../../../common/FiltersButton';

const AbilityRow = ({ data, handleOpenEditForm, handleOpenDeleteDialog }) => {
  const [openAbilities, setOpenAbilities] = useState(false);

  return (
    <>
      <TableRow hover>
        <TableCell align='center' sx={{ maxWidth: 20 }}>
          {(data?.dependencies && data?.dependencies.length > 0) && (
            <IconButton
              aria-label="Habilidades"
              onClick={() => setOpenAbilities(!openAbilities)}
            >
              {openAbilities ? <KeyboardArrowUp /> : <KeyboardArrowDown />}
            </IconButton>
          )}
        </TableCell>
        <TableCell>{data.nome}</TableCell>
        <TableCell>{data.descricao}</TableCell>
        <TableCell align='right' sx={{ minWidth: 110 }}>
          {hasPermission(["admin_sempher"]) && (
            <IconButton aria-label="Editar" onClick={() => handleOpenEditForm(data)}>
              <EditIcon />
            </IconButton>
          )}
          {hasPermission(["admin_sempher"]) && (
            <IconButton aria-label="Excluir" onClick={() => handleOpenDeleteDialog(data)}>
              <TrashIcon />
            </IconButton>
          )}
        </TableCell>
      </TableRow>

      <TableRow>
        <TableCell style={{ paddingBottom: 0, paddingTop: 0 }} colSpan={6}>
          <Collapse in={openAbilities} timeout="auto" unmountOnExit>
            <Box sx={{ margin: 1 }}>
              <AbilityDependencies
                dependencies={data?.dependencies || []}
                handleOpenModalFilial={() => { }}
              />
            </Box>
          </Collapse>
        </TableCell>
      </TableRow>
    </>
  );
};

export function ListOfAbilities() {
  const queryClient = useQueryClient();

  const { exibirAlerta } = useCommonItems();

  const [searchParams, setSearchParams] = useSearchParams();

  const searchParamPage = searchParams.get('page');
  const searchParamPerPage = searchParams.get('perPage');

  // estados para controle de paginacao
  const page = searchParamPage ? (parseInt(searchParamPage) - 1) : 0;
  const rowsPerPage = searchParamPerPage ? parseInt(searchParamPerPage) : 10;

  const [selectedAbility, setSelectedAbilities] = useState(null);
  const [openForm, setOpenForm] = useState(false);
  const [deleteOpenDialog, setOpenDeleteDialog] = useState(false);

  const [isOpenFilters, setIsOpenFilters] = useState(false);
  const [activeFilters, setActiveFilters] = useState(0);

  const queryFilters = {
    limit: rowsPerPage,
    offset: page * rowsPerPage,
  };

  const filterName = searchParams.get('nome');
  if (filterName) {
    queryFilters.nome = filterName;
  }

  const queryKey = ['listaAbilities', queryFilters];

  const { data: listaAbilitiesData, isPending: listaAbilitiesIsLoading } = useQuery({
    queryKey,
    queryFn: () => listaHabilidades(queryFilters)
  });

  const abilities = listaAbilitiesData?.data?.data ?? [];
  const numTotalItems = listaAbilitiesData?.data?.numero_total ?? 0;

  const handleEdit = (item) => {
    setSelectedAbilities(item);
    setOpenForm(true);
  };

  const handleOpenDeleteDialog = (item) => {
    setSelectedAbilities(item);
    setOpenDeleteDialog(true);
  };

  const { mutateAsync: handleDelete } = useMutation({
    mutationFn: deletaHabilidade,
    onSuccess: (response, id) => {
      const message = response.data.message ?? 'Função excluída com sucesso';
      exibirAlerta('Sucesso', message, 'success');

      const cached = queryClient.getQueryData(queryKey);
      const updatedData = {
        ...cached,
        data: {
          ...cached.data,
          data: cached.data.data.filter((item) => item.id !== id),
        },
      };
      queryClient.setQueryData(queryKey, updatedData);
    },
    onError: (error) => {
      const message = error.response?.data?.message ?? 'Erro ao executar operação';
      exibirAlerta('Erro', message, 'error');
    },
    onSettled: () => {
      setOpenDeleteDialog(false);
      setSelectedAbilities(null);
    },
  });

  useEffect(() => {
    checkPermissionsAndRedirectAdmin();

    setSearchParams((state) => {
      state.set('page', String(page + 1));
      state.set('perPage', String(rowsPerPage));

      return state;
    });
  }, [page, rowsPerPage, setSearchParams]);

  useEffect(() => {
    const queryFilters = {
      ...Object.fromEntries(searchParams),
    }

    const tableFilters = { ...queryFilters };
    delete tableFilters.page;
    delete tableFilters.perPage;

    const activeFilters = Object.keys(tableFilters).length;

    setActiveFilters(activeFilters);
    setIsOpenFilters(activeFilters > 0);
  }, [searchParams]);

  return (
    <>
      <Helmet title="Permissões | Admin " defer={false} />

      <Grid container direction="row" justifyContent="flex-start" alignItems="stretch" spacing={3}>
        <Grid item xs={12} md={9}>
          <Breadcrumbs>
            <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao>
            <Typography variant='span'>Gerenciamento Admin</Typography>
            <LinkNavegacao to="/admin/organizacao">Organização</LinkNavegacao>
            <Typography variant="span">Habilidades</Typography>
          </Breadcrumbs>

          <PageTitle
            icon={<CyberSecurityIcon fontSize="large" />}
            title="Permissões (Abilities)"
            description="As Habilidades são utilizadas para definir as permissões de acesso dos usuários."
          />
        </Grid>

        <Grid item xs={12}>
          <Stack direction={{ xs: 'column-reverse', md: 'row' }} spacing={2} justifyContent={'space-between'}>
            <FiltersButton
              isOpenFilters={isOpenFilters}
              setIsOpenFilters={setIsOpenFilters}
              activeFilters={activeFilters}
            />

            <Typography sx={{ textAlign: { xs: 'center', md: 'right' }, mb: 1 }}>
              <Button variant="contained" startIcon={<Add />} onClick={() => setOpenForm(true)}>
                Cadastrar Permissão
              </Button>
            </Typography>
          </Stack>
        </Grid>

        <AbilitiesTableFilter
          isOpenFilters={isOpenFilters}
          isLoadingList={listaAbilitiesIsLoading}
        />

        <Grid item xs={12}>
          <TableContainer component={Paper}>
            <Table aria-label="Habilidades (Abilities)">
              <TableHead>
                <TableRow>
                  <TableCell />
                  <TableCell>Permissão (Ability)</TableCell>
                  <TableCell>Descrição</TableCell>
                  <TableCell />
                </TableRow>
              </TableHead>
              <TableBody>
                {listaAbilitiesIsLoading ? (
                  <SkeletonTableRow
                    firstCellIsIcon
                    numCells={4}
                    numRows={rowsPerPage < 20 ? rowsPerPage : 5}
                  />
                ) : abilities.length > 0 ? (
                  abilities.map((item) => (
                    <AbilityRow
                      key={item.id}
                      data={item}
                      handleOpenEditForm={() => handleEdit(item)}
                      handleOpenDeleteDialog={() => handleOpenDeleteDialog(item)}
                    />
                  ))
                ) : (
                  <EmptyTableRow />
                )}
              </TableBody>
            </Table>

            <Pagination
              numTotalItems={numTotalItems}
              rowsPerPage={rowsPerPage}
              page={page}
            />
          </TableContainer>
        </Grid>
      </Grid>

      <AbilityForm
        open={openForm}
        setOpen={(value) => {
          setOpenForm(value);
          if (!value) {
            setSelectedAbilities(null);
          }
        }}
        allAbilities={abilities}
        ability={selectedAbility}
        queryKey={queryKey}
      />

      <ConfirmDialog
        description="Tem certeza que deseja excluir essa Função?"
        title="Excluir Função"
        goAction={() => handleDelete(selectedAbility.id)}
        handleClose={() => {
          setOpenDeleteDialog(false);
          setSelectedAbilities(null);
        }}
        state={deleteOpenDialog}
      />
    </>
  );
}