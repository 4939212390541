import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import { FilterAlt, Home as HomeIcon } from '@mui/icons-material';
import { WorkerIcon } from '../../../common/Icons';
import PageTitle from '../../../common/PageTitle';
import LinkNavegacao from '../../../common/Link';
import { SelectEmpresaFilter } from '../../../common/SelectEmpresaFilter';
import { TooltipSelectDisabled } from '../../../common/TooltipSelectDisabled';
import { useAppContext } from '../../../../contexts/AppContext';
import { checkPermissionsAndRedirect, getFormErrorMessage, renderDay, verifyFieldsAreFilled } from '../../../../configs/functions';
import { listaEmpresas } from '../../management/companies/configs/functions';
import { exportRetiradaPorFuncToPDF } from './configs/utils';
import { getRetiradasFuncData } from './configs/functions';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { listaUsuariosFilter } from '../../management/people/configs/functions';
import { CustomToolbarReports } from '../../../common/CustomToolbarReports';

export function RelatorioRetiradasFuncionario() {
  const { exibirAlerta } = useCommonItems();
  const { dadosUsuario, getEmpresaIdSession, setEmpresaIdSession } = useAppContext();

  // estados para controle de paginacao
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [numTotalItems, setNumTotalItems] = useState(0);

  const [dataFichaEPI, setDataFichaEPI] = useState([]);
  const [pessoas, setPessoas] = useState([]);
  const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingPessoas, setIsLoadingPessoas] = useState(false);
  const [isSearched, setIsSearched] = useState(false);

  const {
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const columns = useMemo(() => [
    { field: 'produto', headerName: 'Produto', minWidth: 330, flex: 1.5 },
    { field: 'nomeEmpresa', headerName: 'Empresa', minWidth: 200, flex: 1 },
    { field: 'statusEmpresa', headerName: 'Status Empresa', minWidth: 120, flex: 0.5 },
    { field: 'nomeSetor', headerName: 'Setor', minWidth: 150, flex: 0.5 },
    { field: 'nomeArea', headerName: 'Area', minWidth: 200, flex: 1 },
    { field: 'nomePontoEntrega', headerName: 'Ponto de Entrega', minWidth: 200, flex: 1 },
    { field: 'dataRetirada', headerName: 'Data Retirada', minWidth: 160, flex: 0.5 },
  ], []);

  const { data: empresasData, error: listaEmpresasError, isLoading: isLoadingEmpresasData, isPending } = useQuery({
    queryKey: ['listaEmpresas'],
    queryFn: async () => {
      const response = await listaEmpresas();
      const empresasData = response?.data.data;

      let defaultEmpresaId = null;
      const selectedEmpresaIdSession = getEmpresaIdSession();
      if (selectedEmpresaIdSession) {
        defaultEmpresaId = selectedEmpresaIdSession;
      } else {
        defaultEmpresaId = empresasData[0].id;
        setEmpresaIdSession(empresasData[0].id);
      }

      setSelectedEmpresaId(defaultEmpresaId);

      const defaultValues = {
        id_empresas: defaultEmpresaId,
        id_funcionario: '',
        data_inicio: dayjs().subtract(1, 'month'),
        data_fim: dayjs(),
      };
      reset(defaultValues);

      return response;
    },
  });

  const empresas = empresasData?.data.data || [];
  const isLoadingEmpresas = isLoadingEmpresasData || isPending;

  async function loadReportData(id_empresa, id_funcionario, data_inicio, data_fim) {
    try {
      setIsLoading(true);

      data_inicio = dayjs(data_inicio).format('YYYY-MM-DD');
      data_fim = dayjs(data_fim).format('YYYY-MM-DD');

      const filter = {
        id_empresa,
        id_funcionario,
        data_inicio,
        data_fim,
        limit: rowsPerPage,
        offset: page * rowsPerPage,
      };
      const res = await getRetiradasFuncData(filter);
      const retiradas = res.data.retiradas;
      const getRetiradasFunc = retiradas[0]?.retiradas || [];
      setDataFichaEPI(getRetiradasFunc);
      setNumTotalItems(res.data.numero_total);
    } catch (error) {
      console.log(error);
      exibirAlerta('Ops', 'Ocorreu um erro ao carregar dados do relatório.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsSearched(true);
      loadReportData(data.id_empresa, data.id_funcionario, data.data_inicio, data.data_fim);
    } catch (error) {
      exibirAlerta('Erro', 'Erro ao carregar dados do relatório', 'error');
      setIsSearched(false);
    }
  };

  useEffect(() => {
    const requiredPermissionsView = ["admin", "admin_relatorio"];
    checkPermissionsAndRedirect(requiredPermissionsView);

    return () => {
      reset();
    }
  }, []);

  const carregaColaboradores = async (empresaId) => {
    try {
      setIsLoadingPessoas(true);
      const res = await listaUsuariosFilter({ "id_empresa": empresaId });
      setPessoas(res.data.data);
    } catch (error) {
      console.error('Erro ao carregar colaboradores:', error);
    } finally {
      setIsLoadingPessoas(false);
    }
  }

  useEffect(() => {
    carregaColaboradores(selectedEmpresaId);
  }, [selectedEmpresaId]);

  useEffect(() => {
    const id_empresa = selectedEmpresaId;
    const id_funcionario = getValues('id_funcionario');
    const data_inicio = getValues('data_inicio')
    const data_fim = getValues('data_fim')

    if (!id_empresa || !id_funcionario || !data_inicio || !data_fim) {
      return;
    }

    loadReportData(id_empresa, id_funcionario, data_inicio, data_fim);
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (listaEmpresasError) {
      exibirAlerta('Ops', 'Erro ao carregar empresas', 'error');
    }
  }, [exibirAlerta, listaEmpresasError])

  function exportDataToPDF() {
    if (!getValues('id_funcionario')) {
      return exibirAlerta('Ops', 'Selecione um colaborador para exportar', 'warning');
    }

    // if date range is not selected
    if (!getValues('data_inicio') || !getValues('data_fim')) {
      return exibirAlerta('Ops', 'Selecione um período para exportar', 'warning');
    }

    exportRetiradaPorFuncToPDF({
      colaborador: pessoas.find(pessoa => pessoa.id == getValues('id_funcionario')),
      tableData: dataFichaEPI,
      data_inicio: getValues('data_inicio'),
      data_fim: getValues('data_fim'),
      selectEmpresa: empresas.find(empresa => empresa.id == selectedEmpresaId),
      dadosUsuario
    })
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={3}
    >
      <Grid item xs={12}>
        <Breadcrumbs>
          <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao>
          <LinkNavegacao to='/epis'>Gestão de EPI's</LinkNavegacao>
          <LinkNavegacao to='/epis/relatorios'>Relatórios</LinkNavegacao>
          <Typography variant='span'>Relatório de Retiradas por Funcionário</Typography>
        </Breadcrumbs>
        <PageTitle icon={<WorkerIcon fontSize='large' />} title="Relatório de Retiradas por Funcionário" />
      </Grid>

      <Grid container item xs={12} spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid container item xs={12} md={12} spacing={3}>
          <Grid item xs={12} md={6}>
            <SelectEmpresaFilter
              empresas={empresas}
              id_empresas={empresas}
              defaultValue={selectedEmpresaId}
              onChangeValue={(value) => {
                setIsSearched(false);
                setValue('id_empresa', value, { shouldDirty: true });
                setSelectedEmpresaId(value);
                setEmpresaIdSession(value);
                setValue('id_funcionario', null, { shouldDirty: true })
              }}
              textHelper={false}
              isLoading={isLoadingEmpresas}
            />
          </Grid>

          {pessoas &&
            <Grid item xs={12} md={6}>
              <TooltipSelectDisabled isDisabled={selectedEmpresaId}>
                <Controller
                  name="id_funcionario"
                  control={control}
                  rules={{ required: true }}
                  render={({ field: { ref, onChange, ...field } }) => (
                    <Autocomplete
                      {...field}
                      options={pessoas}
                      getOptionLabel={(option) => {
                        // verify if the user exists in pessoas
                        const pessoa = pessoas.find(p => p.id === option.id);
                        return pessoa ? `${pessoa.matricula} - ${pessoa.nome}` : '';
                      }}
                      renderOption={(props, option) => (
                        <MenuItem {...props} key={option.id} disabled={option.status === 0}>
                          {option.matricula} - {option.nome} {option.status === 0 ? '- (Inativo)' : ''}
                        </MenuItem>
                      )}
                      onChange={(event, value) => {
                        setIsSearched(false);
                        setValue('id_funcionario', value ? value.id : null, { shouldDirty: true });
                        if (value) {
                          clearErrors('id_funcionario');
                        }
                      }}
                      loading={isLoadingPessoas}
                      loadingText="Carregando..."
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          label="Funcionário"
                          error={!!errors.id_funcionario}
                          helperText={getFormErrorMessage(errors, 'id_funcionario')}
                          size="small"
                          disabled={!selectedEmpresaId}
                          InputProps={{
                            ...params.InputProps,
                            style: { backgroundColor: '#fff' },
                            endAdornment: (
                              <>
                                {isLoadingPessoas && <CircularProgress color="inherit" size={16} />}
                                {params.InputProps.endAdornment}
                              </>
                            ),
                          }}
                        />
                      )}
                      noOptionsText="Nenhum disponível"
                    />
                  )}
                />
              </TooltipSelectDisabled>
            </Grid>
          }
        </Grid>

        <Grid container item xs={12} md={12} spacing={3}>
          <Grid item xs={12} md={4} lg={3}>
            <DatePicker
              sx={{ width: '100%' }}
              label="Data de Início"
              size='small'
              format="DD/MM/YYYY"
              openTo="day"
              maxDate={dayjs()}
              dayOfWeekFormatter={renderDay}
              defaultValue={dayjs().subtract(1, 'month')}
              onChange={(data) => {
                setValue('data_inicio', data, { shouldDirty: true })
                if (data && data.isBefore(dayjs())) {
                  clearErrors('data_inicio');
                }
              }}
              onError={(error) => {
                if (error) {
                  const errorMessage = error === "maxDate" ? "Data de início não pode ser maior que a data atual" : "Data inválida";
                  setError("data_inicio", { type: "invalid", message: errorMessage })
                }
              }}
              slotProps={{
                textField: {
                  error: !!errors.data_inicio,
                  helperText: errors.data_inicio ? errors.data_inicio.message : null,
                  size: 'small',
                  style: {
                    backgroundColor: '#fff',
                    borderRadius: 4,
                  }
                }
              }}
              TextField={(params) => (
                <TextField
                  size='small'
                  autoComplete='off'
                  fullWidth
                  type="date"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={4} lg={3}>
            <DatePicker
              sx={{ width: '100%' }}
              label="Data de Fim"
              size='small'
              format="DD/MM/YYYY"
              openTo="day"
              maxDate={dayjs().add(1, 'day')}
              dayOfWeekFormatter={renderDay}
              defaultValue={dayjs()}
              onChange={(data) => {
                setValue('data_fim', data, { shouldDirty: true })
                if (data && data.isBefore(dayjs().add(1, 'day'))) {
                  clearErrors('data_fim');
                }
              }}
              onError={(error) => {
                if (error) {
                  const errorMessage = error === "maxDate" ? "Data de fim não pode ser maior que a data atual" : "Data inválida";
                  setError("data_fim", { type: "invalid", message: errorMessage })
                }
              }}
              slotProps={{
                textField: {
                  error: !!errors.data_fim,
                  helperText: errors.data_fim ? errors.data_fim.message : null,
                  size: 'small',
                  style: {
                    backgroundColor: '#fff',
                    borderRadius: 4,
                  }
                },
              }}
              TextField={(params) => (
                <TextField
                  size='small'
                  autoComplete='off'
                  fullWidth
                  type="date"
                />
              )}
            />
          </Grid>

          <Grid item xs={12} md={1}>
            <Button
              type='submit'
              color='primary'
              variant='contained'
              disabled={isLoading || Object.keys(errors).length > 0 || !verifyFieldsAreFilled([getValues('id_funcionario'), getValues('data_inicio'), getValues('data_fim')])}
              startIcon={isLoading ? <CircularProgress size={16} sx={{ color: "textSecondary" }} /> : <FilterAlt />}
            >
              Filtrar
            </Button>
          </Grid>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{
          height: dataFichaEPI.length > 0 ? "auto" : 400,
          minHeight: 400,
          width: '100%',
          '& .MuiDataGrid-columnHeaders, .MuiDataGrid-toolbarContainer': {
            backgroundColor: '#d8d8d8',
          },
          '& .MuiDataGrid-columnHeaders': {
            borderRadius: 0
          },
        }}>
          <DataGrid
            rows={dataFichaEPI}
            columns={columns}
            loading={isLoading}
            pageSizeOptions={[10, 20, 50, 100]}
            rowCount={numTotalItems}
            paginationMode="server"
            paginationModel={{ pageSize: rowsPerPage, page: page }}
            onPaginationModelChange={({ page, pageSize }) => {
              setPage(page);
              setRowsPerPage(pageSize);
            }}
            sx={{
              backgroundColor: '#fff',
              '.MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold !important',
                overflow: 'visible !important',
              }
            }}
            disableRowSelectionOnClick
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            slots={{
              toolbar: (props) =>
                <CustomToolbarReports
                  reportName="relatorio_retiradas_funcionario_"
                  columns={columns}
                  pdfExport={exportDataToPDF}
                  props={props}
                />,
              loadingOverlay: LinearProgress,
              noRowsOverlay: () => (
                <Box style={{ display: "flex", width: '100%', textAlign: 'center', height: "100%", alignItems: 'center', justifyContent: 'center' }}>
                  {isSearched ?
                    <Typography variant='h6' color='textSecondary'>Nenhum registro encontrado para o filtro selecionado</Typography>
                    :
                    <Typography variant='h6' color='textSecondary'>Selecione um colaborador e o período que deseja filtrar</Typography>
                  }
                </Box>
              )
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}