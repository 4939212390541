import { useState } from 'react';
import ListSubheader from '@mui/material/ListSubheader';
import List from '@mui/material/List';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Collapse from '@mui/material/Collapse';
import { Divider, Drawer, IconButton, Stack } from '@mui/material';
import MenuTitle from './MenuTitle';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import CloseIcon from '@mui/icons-material/Close';
import { useAppContext } from '../../contexts/AppContext';
import MenuEpis from '../../components/screens/epis/menu';
import { LinkMenu } from '../../configs/functions';
import MenuTraining from '../../components/screens/training/menu';
import MenuRH from '../../components/screens/humanResources/menu';
import { ClientManagementIcon, InfoIcon, OfficeIcon, ProductDocumentsIcon, RadarIcon, SettingsIcon, SupportIcon, ThumbnailsIcon } from './Icons';

export default function GeneralMenu() {
    const { dadosUsuario, drawerOpen, saveMenuStateToLocalStorage, getMenuStateFromLocalStorage, setDrawerOpen, activeModule } = useAppContext();

    const [openMenuEpi, setOpenMenuEpi] = useState(getMenuStateFromLocalStorage('openMenuEpi', true));
    const [openMenuGerenciamento, setOpenMenuGerenciamento] = useState(getMenuStateFromLocalStorage('openMenuGerenciamento', false));
    const [openMenuSempher, setOpenMenuSempher] = useState(getMenuStateFromLocalStorage('openMenuSempher', true));

    /** Checa se a media Query é menor que SM  */
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('lg'));

    const handleMenuEpiClick = () => {
        const newState = !openMenuEpi;
        setOpenMenuEpi(newState);
        saveMenuStateToLocalStorage('openMenuEpi', newState);

        // não permitir fechar ambos fechados simultaneamente
        if (!newState && !openMenuGerenciamento) {
            setOpenMenuGerenciamento(true);
            saveMenuStateToLocalStorage('openMenuGerenciamento', true);
        }
    };

    const handleMenuGerenciamentoClick = () => {
        const newState = !openMenuGerenciamento;
        setOpenMenuGerenciamento(newState);
        saveMenuStateToLocalStorage('openMenuGerenciamento', newState);

        // não permitir fechar ambos fechados simultaneamente
        if (!newState && !openMenuEpi) {
            setOpenMenuEpi(true);
            saveMenuStateToLocalStorage('openMenuEpi', true);
        }
    };

    const handleMenuSempherClick = () => {
        const newState = !openMenuSempher;
        setOpenMenuSempher(newState);
        saveMenuStateToLocalStorage('openMenuSempher', newState);
    };

    const menu = () => {
        return (<>
            <List
                sx={{
                    paddingTop: 1,
                    width: '100%',
                    minHeight: {
                        md: '100vh'
                    },
                    height: {
                        md: "100%"
                    },
                    bgcolor: 'background.paper',
                    boxShadow: {
                        xs: 0,
                        lg: 2
                    }
                }}
                component="nav"
                aria-labelledby="nested-list-subheader"
                disablePadding
                subheader={
                    <Stack
                        direction="row"
                        justifyContent="space-between"
                        alignItems="flex-start"
                        spacing={2}
                    >
                        <ListSubheader style={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {dadosUsuario ? dadosUsuario?.pessoa?.empresa?.nome : ''}
                        </ListSubheader>
                        <IconButton
                            onClick={() => { setDrawerOpen(false) }}
                            sx={{
                                display: {
                                    xs: 'block',
                                    lg: 'none'
                                },
                                color: 'inherit'
                            }}><CloseIcon />
                        </IconButton>
                    </Stack>
                }
            >

                {(activeModule === 'epis') && <MenuEpis openMenuEpi={openMenuEpi} handleClick={handleMenuEpiClick} />}
                {(activeModule === 'treinamentos') && <MenuTraining openMenuEpi={openMenuEpi} handleClick={() => { }} />}
                {(activeModule === 'rh') && <MenuRH openMenuEpi={openMenuEpi} handleClick={() => { }} />}

                <Divider variant='middle' sx={{ my: 2 }} />

                <MenuTitle
                    title={'Gerenciamento'}
                    icon={<ThumbnailsIcon />}
                    stateVar={openMenuGerenciamento}
                    fChangeState={handleMenuGerenciamentoClick}
                />
                <Collapse in={openMenuGerenciamento} timeout="auto" unmountOnExit>
                    <List disablePadding>
                        <LinkMenu url='/gerenciamento/empresas' setDrawerOpen={setDrawerOpen} >
                            <ListItemButton>
                                <ListItemIcon>
                                    <OfficeIcon />
                                </ListItemIcon>
                                <ListItemText primary="Empresas" />
                            </ListItemButton>
                        </LinkMenu>

                        <LinkMenu url='/gerenciamento/organizacao' setDrawerOpen={setDrawerOpen}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <ProductDocumentsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Organização" />
                            </ListItemButton>
                        </LinkMenu>

                        <LinkMenu url='/gerenciamento/pessoas' setDrawerOpen={setDrawerOpen}>
                            <ListItemButton>
                                <ListItemIcon>
                                    <ClientManagementIcon />
                                </ListItemIcon>
                                <ListItemText primary="Pessoas" />
                            </ListItemButton>
                        </LinkMenu>

                        {/* <LinkMenu url='/gerenciamento/configuracoes' setDrawerOpen={setDrawerOpen} >
                            <ListItemButton >
                                <ListItemIcon>
                                    <SettingsIcon />
                                </ListItemIcon>
                                <ListItemText primary="Configurações" />
                            </ListItemButton>
                        </LinkMenu> */}

                        <Divider variant='middle' />

                        <LinkMenu url='/gerenciamento/detalhes' setDrawerOpen={setDrawerOpen}>
                            <ListItemButton >
                                <ListItemIcon>
                                    <InfoIcon />
                                </ListItemIcon>
                                <ListItemText primary="Detalhes" />
                            </ListItemButton>
                        </LinkMenu>
                    </List>
                </Collapse>

                {dadosUsuario?.isSempher && (
                    <>
                        <Divider variant='middle' sx={{ my: 2 }} />

                        <MenuTitle
                            title="Admin Sempher"
                            icon={<SupportIcon />}
                            stateVar={openMenuSempher}
                            fChangeState={handleMenuSempherClick}
                        />

                        <Collapse in={openMenuSempher} timeout="auto" unmountOnExit>
                            <List disablePadding>
                                <LinkMenu url='/admin/painel' setDrawerOpen={setDrawerOpen} >
                                    <ListItemButton >
                                        <ListItemIcon>
                                            <RadarIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Painel de Monitoramento" />
                                    </ListItemButton>
                                </LinkMenu>
                            </List>

                            <List disablePadding>
                                <LinkMenu url='/admin/organizacao' setDrawerOpen={setDrawerOpen} >
                                    <ListItemButton >
                                        <ListItemIcon>
                                            <SettingsIcon />
                                        </ListItemIcon>
                                        <ListItemText primary="Organização" />
                                    </ListItemButton>
                                </LinkMenu>
                            </List>
                        </Collapse>
                    </>
                )}
            </List>
        </>)

        /** Fim da função Menu */
    }

    const renderMenu = () => {

        if (matches) {

            return (<Drawer
                anchor='left'
                open={drawerOpen}
                onClose={() => { setDrawerOpen(false) }}
            >
                {menu()}
            </Drawer >
            )
        }

        return menu()

    }

    return (
        <>
            {renderMenu()}
        </>
    );
}