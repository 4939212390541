import { useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import dayjs from 'dayjs';
import { useQuery } from '@tanstack/react-query';
import {
  Autocomplete,
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { DatePicker } from '@mui/x-date-pickers';
import { grey } from '@mui/material/colors';
import { FilterAlt, Home as HomeIcon } from '@mui/icons-material';
import PageTitle from '../../../common/PageTitle';
import LinkNavegacao from '../../../common/Link';
import { SelectEmpresaFilter } from '../../../common/SelectEmpresaFilter';
import { useAppContext } from '../../../../contexts/AppContext';
import { checkPermissionsAndRedirect, getFormErrorMessage, renderDay, verifyFieldsAreFilled } from '../../../../configs/functions';
import { listaEmpresas } from '../../management/companies/configs/functions';
import { exportPendenciasRetiradasToPDF } from './configs/utils';
import { getPendenciasRetiradasData } from './configs/functions';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { TooltipSelectDisabled } from '../../../common/TooltipSelectDisabled';
import { listaAreas, listaSetores } from '../../management/organization/configs/functions';
import { carregaEquipamentos } from '../equipments/configs/functions';
import { CustomToolbarReports } from '../../../common/CustomToolbarReports';
import { listaUsuariosFilter } from '../../management/people/configs/functions';
import { ExpiredIcon } from '../../../common/Icons';

export function RelatorioPendenciasRetiradas() {
  const { exibirAlerta, exibirDialog, handleCloseDialog } = useCommonItems();
  const { dadosUsuario, getEmpresaIdSession, setEmpresaIdSession } = useAppContext();

  // estados para controle de paginacao
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [numTotalItems, setNumTotalItems] = useState(0);

  const [reportData, setReportData] = useState([]);

  const [pessoas, setPessoas] = useState([]);
  const [setores, setSetores] = useState([]);
  const [areas, setAreas] = useState([]);
  const [equipamentos, setEquipamentos] = useState([]);

  const [selectedEmpresaId, setSelectedEmpresaId] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSearched, setIsSearched] = useState(false);

  const {
    handleSubmit,
    setValue,
    getValues,
    setError,
    clearErrors,
    reset,
    control,
    formState: { errors },
  } = useForm();

  const columns = useMemo(() => [
    { field: 'nomeFuncionario', headerName: 'Funcionário', minWidth: 200 },
    { field: 'nomeEmpresa', headerName: 'Empresa', minWidth: 200 },
    { field: 'tituloSetor', headerName: 'Setor', minWidth: 200 },
    { field: 'nomeArea', headerName: 'Área', minWidth: 200 },
    { field: 'nomeGrupo', headerName: 'Grupo', minWidth: 200 },
    { field: 'nomeFuncao', headerName: 'Função', minWidth: 200 },
    { field: 'produtoCA', headerName: 'CA', minWidth: 90 },
    { field: 'nomeProduto', headerName: 'Produto', minWidth: 250 },
    { field: 'diasPendentes', headerName: 'Dias Pendentes', minWidth: 120 },
    { field: 'dataProximaRetirada', headerName: 'Data Próxima Retirada', minWidth: 200 },
    { field: 'dataRetirada', headerName: 'Data Retirada', minWidth: 200 },
  ], []);

  const { data: empresasData, error: listaEmpresasError, isLoading: isLoadingEmpresasData, isPending } = useQuery({
    queryKey: ['listaEmpresas'],
    queryFn: async () => {
      const response = await listaEmpresas();
      const empresasData = response?.data.data;

      let defaultEmpresaId = null;
      const selectedEmpresaIdSession = getEmpresaIdSession();
      if (selectedEmpresaIdSession) {
        defaultEmpresaId = selectedEmpresaIdSession;
      } else {
        defaultEmpresaId = empresasData[0].id;
        setEmpresaIdSession(empresasData[0].id);
      }

      setSelectedEmpresaId(defaultEmpresaId);
      getDataEmpresa(defaultEmpresaId);

      const defaultValues = {
        id_empresas: defaultEmpresaId,
        id_setor: '',
        id_area: '',
        id_equipamento: '',
        id_pessoa: '',
        data_inicio: dayjs().subtract(1, 'month'),
        data_fim: dayjs(),
      };
      reset(defaultValues);
      return response;
    },
  });

  const empresas = empresasData?.data.data || [];
  const isLoadingEmpresas = isLoadingEmpresasData || isPending;

  const carregaSetores = async (empresaId) => {
    exibirDialog('Carregando setores...');
    try {
      const filterAll = { id_empresas: Array.isArray(empresaId) ? empresaId : [empresaId] };
      const res = await listaSetores(filterAll);
      setSetores(res.data.data);
    } catch (error) {
      console.error('Erro ao carregar Setores:', error);
    } finally {
      handleCloseDialog();
    }
  }

  const carregaAreas = async (empresaId) => {
    exibirDialog('Carregando Áreas...');
    try {
      const filterAll = { id_empresas: Array.isArray(empresaId) ? empresaId : [empresaId] };
      const res = await listaAreas(filterAll);
      setAreas(res.data.data);
    } catch (error) {
      console.error('Erro ao carregar áreas:', error);
    } finally {
      handleCloseDialog();
    }
  }

  const carregaColaboradores = async (empresaId) => {
    exibirDialog('Carregando Colaboradores...');
    try {
      const res = await listaUsuariosFilter({ "id_empresa": empresaId });
      setPessoas(res.data.data);
    } catch (error) {
      console.error('Erro ao carregar colaboradores:', error);
    } finally {
      handleCloseDialog();
    }
  }

  const listaEquipamentos = async (empresaId) => {
    try {
      exibirDialog('Carregando Equipamentos...');
      const filterAllMachines = { id_empresas: Array.isArray(empresaId) ? empresaId : [empresaId] };
      const response = await carregaEquipamentos(filterAllMachines);
      setEquipamentos(response.data.data);
    } catch (error) {
      exibirAlerta('Erro ao carregar os Equipamentos', '', 'error');
    } finally {
      handleCloseDialog();
    }
  }

  async function loadReportData(id_empresa, id_pessoa, id_setor, id_area, id_equipamento, data_inicio, data_fim) {
    if (!id_empresa) return;

    try {
      setIsLoading(true);

      data_inicio = dayjs(data_inicio).format('YYYY-MM-DD');
      data_fim = dayjs(data_fim).format('YYYY-MM-DD');

      const filter = {
        data_inicio,
        data_fim,
        limit: rowsPerPage,
        offset: page * rowsPerPage,
      };

      if (id_empresa === 'all') {
        filter.empresa = 1
      } else {
        filter.id_empresa = id_empresa
      }
      if (id_setor) filter.id_setor = id_setor;
      if (id_area) filter.id_area = id_area;
      if (id_equipamento) filter.id_equipamento = id_equipamento;
      if (id_pessoa) filter.id_pessoa = id_pessoa;

      const res = await getPendenciasRetiradasData(filter);
      setReportData(res.data.pendencias);
      setNumTotalItems(res.data.numero_total);
    } catch (error) {
      console.log(error);
      exibirAlerta('Ops', 'Ocorreu um erro ao carregar dados do relatório.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsSearched(true);
      loadReportData(data.id_empresas, data.id_pessoa, data.id_setor, data.id_area, data.id_equipamento, data.data_inicio, data.data_fim);
    } catch (error) {
      exibirAlerta('Erro', 'Erro ao carregar dados do relatório', 'error');
      setIsSearched(false);
    }
  };

  function exportDataToPDF() {
    if (!getValues('id_empresas')) {
      return exibirAlerta('Ops', 'Selecione ao menos empresa', 'warning');
    }

    // if date range is not selected
    if (!getValues('data_inicio') || !getValues('data_fim')) {
      return exibirAlerta('Ops', 'Selecione um período para exportar', 'warning');
    }

    exportPendenciasRetiradasToPDF({
      tableData: reportData,
      data_inicio: getValues('data_inicio'),
      data_fim: getValues('data_fim'),
      selectEmpresa: empresas.find(empresa => empresa.id === selectedEmpresaId),
      dadosUsuario
    })
  }

  function getDataEmpresa(empresaId) {
    carregaSetores(empresaId);
    carregaAreas(empresaId);
    listaEquipamentos(empresaId);
    carregaColaboradores(empresaId);
  }

  useEffect(() => {
    const requiredPermissionsView = ["admin", "admin_relatorio"];
    checkPermissionsAndRedirect(requiredPermissionsView);

    return () => {
      reset();
    }
  }, [reset]);

  useEffect(() => {
    if (selectedEmpresaId) {
      getDataEmpresa(selectedEmpresaId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedEmpresaId]);

  useEffect(() => {
    if (listaEmpresasError) {
      exibirAlerta('Erro', 'Erro ao carregar empresas', 'error');
    }
  }, [exibirAlerta, listaEmpresasError])

  useEffect(() => {
    const id_empresas = selectedEmpresaId;
    const id_pessoa = getValues('id_pessoa');
    const id_setor = getValues('id_setor');
    const id_area = getValues('id_area');
    const id_equipamento = getValues('id_equipamento');
    const data_inicio = getValues('data_inicio')
    const data_fim = getValues('data_fim')

    if (!id_empresas || !data_inicio || !data_fim) {
      return;
    }

    loadReportData(id_empresas, id_pessoa, id_setor, id_area, id_equipamento, data_inicio, data_fim);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage, selectedEmpresaId]);

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={3}
    >
      <Grid item xs={12}>
        <Breadcrumbs>
          <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao>
          <LinkNavegacao to='/epis'>Gestão de EPI's</LinkNavegacao>
          <LinkNavegacao to='/epis/relatorios'>Relatórios</LinkNavegacao>
          <Typography variant='span'>Relatório de Pendências de Retiradas</Typography>
        </Breadcrumbs>
        <PageTitle
          icon={<ExpiredIcon fontSize='large' />}
          title="Relatório de Pendências de Retiradas"
        />
      </Grid>

      <Grid container item xs={12} spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid item xs={12} md={4} lg={3}>
          <SelectEmpresaFilter
            empresas={empresas}
            id_empresas={empresas}
            defaultValue={selectedEmpresaId}
            onChangeValue={(value) => {
              setIsSearched(false);
              setSelectedEmpresaId(value);
              setEmpresaIdSession(value);
              setValue('id_empresas', value, { shouldDirty: true });
              setValue('id_pessoa', '', { shouldDirty: true });
              setValue('id_area', '', { shouldDirty: true });
              setValue('id_setor', '', { shouldDirty: true });
              setValue('id_equipamento', '', { shouldDirty: true });
            }}
            textHelper={false}
            isLoading={isLoadingEmpresas}
          />
        </Grid>

        <Grid item xs={12} md={4} lg={3}>
          <TooltipSelectDisabled isDisabled={selectedEmpresaId}>
            <Controller
              name="id_pessoa"
              control={control}
              render={({ field: { ref, onChange, ...field } }) => (
                <Autocomplete
                  {...field}
                  options={pessoas || []}
                  getOptionLabel={(option) => {
                    // verify if the user exists in pessoas
                    const pessoa = pessoas.find(p => p.id === option.id);
                    return pessoa ? `${pessoa.matricula} - ${pessoa.nome}` : '';
                  }}
                  renderOption={(props, option) => (
                    <MenuItem {...props} key={option.id} disabled={option.status === 0}>
                      {option.matricula} - {option.nome} {option.status === 0 ? '- (Inativo)' : ''}
                    </MenuItem>
                  )}
                  onChange={(event, value) => {
                    setIsSearched(false);
                    setValue('id_pessoa', value ? value.id : null, { shouldDirty: true });
                    if (value) {
                      clearErrors('id_pessoa');
                    }
                  }}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label="Funcionário"
                      error={!!errors.id_pessoa}
                      helperText={getFormErrorMessage(errors, 'id_pessoa')}
                      size="small"
                      disabled={!selectedEmpresaId}
                      InputProps={{ ...params.InputProps, style: { backgroundColor: '#fff' } }}
                    />
                  )}
                  noOptionsText="Nenhum disponível"
                />
              )}
            />
          </TooltipSelectDisabled>
        </Grid>

        <Grid item xs={12} md={4} lg={3}>
          <TooltipSelectDisabled isDisabled={selectedEmpresaId}>
            <Controller
              name='id_setor'
              control={control}
              rules={{ required: false }}
              defaultValue={''}
              render={({ field: { ref, onChange, ...field } }) => (
                <TextField
                  {...field}
                  select
                  fullWidth
                  label="Setor"
                  disabled={!selectedEmpresaId}
                  onChange={(e) => {
                    setIsSearched(false);
                    setValue('id_setor', e.target.value, { shouldDirty: true })
                    if (e.target.value) {
                      clearErrors("id_setor");
                    }
                  }}
                  error={!!errors.id_setor}
                  helperText={getFormErrorMessage(errors, 'id_setor')}
                  size='small'
                  InputProps={{ style: { backgroundColor: '#fff' } }}
                >
                  <MenuItem value=''>Todos</MenuItem>
                  {setores.length > 0 ? (
                    [
                      ...setores.filter(option => option.status[0] !== 0).map(option => (
                        <MenuItem key={option.setores_id[0]} value={option.setores_id[0]}>
                          {option.titulo}
                        </MenuItem>
                      )),
                      ...setores.filter(option => option.status[0] === 0).map(option => (
                        <MenuItem key={option.setores_id[0]} value={option.setores_id[0]} disabled>
                          {option.titulo} - (Inativo)
                        </MenuItem>
                      ))
                    ]
                  ) : (
                    <MenuItem disabled>Nenhum disponível</MenuItem>
                  )}
                </TextField>
              )}
            />
          </TooltipSelectDisabled>
        </Grid>

        <Grid item xs={12} md={4} lg={3}>
          <TooltipSelectDisabled isDisabled={selectedEmpresaId}>
            <Controller
              name='id_area'
              control={control}
              rules={{ required: false }}
              defaultValue={''}
              render={({ field: { ref, onChange, ...field } }) => (
                <TextField
                  {...field}
                  select
                  fullWidth
                  label="Área"
                  disabled={!selectedEmpresaId}
                  onChange={(e) => {
                    setIsSearched(false);
                    setValue('id_area', e.target.value, { shouldDirty: true })
                    if (e.target.value) {
                      clearErrors("id_area");
                    }
                  }}
                  error={!!errors.id_area}
                  helperText={getFormErrorMessage(errors, 'id_area')}
                  size='small'
                  InputProps={{ style: { backgroundColor: '#fff' } }}
                >
                  <MenuItem value=''>Todos</MenuItem>
                  {areas.length > 0 ? (
                    [
                      ...areas.filter(option => option.status[0] !== 0).map(option => (
                        <MenuItem key={option.areas_id[0]} value={option.areas_id[0]}>
                          {option.nome}
                        </MenuItem>
                      )),
                      ...areas.filter(option => option.status[0] === 0).map(option => (
                        <MenuItem key={option.areas_id[0]} value={option.areas_id[0]} disabled>
                          {option.nome} - (Inativo)
                        </MenuItem>
                      ))
                    ]
                  ) : (
                    <MenuItem disabled>Nenhum disponível</MenuItem>
                  )}
                </TextField>
              )}
            />
          </TooltipSelectDisabled>
        </Grid>

        <Grid item xs={12} md={4} lg={3}>
          <TooltipSelectDisabled isDisabled={selectedEmpresaId}>
            <Controller
              name='id_equipamento'
              control={control}
              rules={{ required: false }}
              defaultValue={''}
              render={({ field: { ref, onChange, ...field } }) => (
                <TextField
                  {...field}
                  select
                  fullWidth
                  label="Equipamento"
                  disabled={!selectedEmpresaId}
                  onChange={(e) => {
                    setIsSearched(false);
                    setValue('id_equipamento', e.target.value, { shouldDirty: true })
                    if (e.target.value) {
                      clearErrors("id_equipamento");
                    }
                  }}
                  error={!!errors.id_equipamento}
                  helperText={getFormErrorMessage(errors, 'id_equipamento')}
                  size='small'
                  InputProps={{ style: { backgroundColor: '#fff' } }}
                >
                  <MenuItem value=''>Todos</MenuItem>
                  {equipamentos.length > 0 ? (
                    [
                      ...equipamentos.filter(option => option.status !== 0).map(option => (
                        <MenuItem key={option.id} value={option.id}>
                          {option.nome} - ({option.tipo === 'maquina' ? 'Máquina' : 'Balcão'})
                        </MenuItem>
                      )),
                      ...equipamentos.filter(option => option.status === 0).map(option => (
                        <MenuItem key={option.id} value={option.id} disabled>
                          {option.nome} - ({option.tipo === 'maquina' ? 'Máquina' : 'Balcão'}) - Inativo
                        </MenuItem>
                      ))
                    ]
                  ) : (
                    <MenuItem disabled>Nenhum disponível</MenuItem>
                  )}
                </TextField>
              )}
            />
          </TooltipSelectDisabled>
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <DatePicker
            sx={{ width: '100%' }}
            label="Data de Início"
            size='small'
            format="DD/MM/YYYY"
            openTo="day"
            maxDate={dayjs()}
            dayOfWeekFormatter={renderDay}
            defaultValue={dayjs().subtract(1, 'month')}
            onChange={(data) => {
              setValue('data_inicio', data, { shouldDirty: true })
              if (data && data.isBefore(dayjs())) {
                clearErrors('data_inicio');
              }
            }}
            onError={(error) => {
              if (error) {
                const errorMessage = error === "maxDate" ? "Data de início não pode ser maior que a data atual" : "Data inválida";
                setError("data_inicio", { type: "invalid", message: errorMessage })
              }
            }}
            slotProps={{
              textField: {
                error: !!errors.data_inicio,
                helperText: errors.data_inicio ? errors.data_inicio.message : null,
                size: 'small',
                style: {
                  backgroundColor: '#fff',
                  borderRadius: 4,
                }
              }
            }}
            TextField={(params) => (
              <TextField
                size='small'
                autoComplete='off'
                fullWidth
                type="date"
              />
            )}
          />
        </Grid>

        <Grid item xs={6} md={4} lg={3}>
          <DatePicker
            sx={{ width: '100%' }}
            label="Data de Fim"
            size='small'
            format="DD/MM/YYYY"
            openTo="day"
            maxDate={dayjs().add(1, 'day')}
            dayOfWeekFormatter={renderDay}
            defaultValue={dayjs()}
            onChange={(data) => {
              setValue('data_fim', data, { shouldDirty: true })
              if (data && data.isBefore(dayjs().add(1, 'day'))) {
                clearErrors('data_fim');
              }
            }}
            onError={(error) => {
              if (error) {
                const errorMessage = error === "maxDate" ? "Data de fim não pode ser maior que a data atual" : "Data inválida";
                setError("data_fim", { type: "invalid", message: errorMessage })
              }
            }}
            slotProps={{
              textField: {
                error: !!errors.data_fim,
                helperText: errors.data_fim ? errors.data_fim.message : null,
                size: 'small',
                style: {
                  backgroundColor: '#fff',
                  borderRadius: 4,
                }
              },
            }}
            TextField={(params) => (
              <TextField
                size='small'
                autoComplete='off'
                fullWidth
                type="date"
              />
            )}
          />
        </Grid>

        <Grid item xs={12} md={2}>
          <Button
            type='submit'
            color='primary'
            variant='contained'
            disabled={
              isLoading ||
              Object.keys(errors).length > 0 ||
              !verifyFieldsAreFilled([getValues('id_empresas'), getValues('data_inicio'), getValues('data_fim')])
            }
            startIcon={isLoading ? <CircularProgress size={16} sx={{ color: "textSecondary" }} /> : <FilterAlt />}
          >
            Filtrar
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{
          height: reportData.length > 0 ? "auto" : 400,
          minHeight: 400,
          width: '100%',
          overflowX: "auto",
          '& .MuiDataGrid-columnHeaders, .MuiDataGrid-toolbarContainer': {
            backgroundColor: '#d8d8d8',
          },
          '& .MuiDataGrid-columnHeaders': {
            borderRadius: 0
          },
        }}>
          <DataGrid
            getRowId={(row) => row.idRetirada}
            rows={reportData}
            columns={columns}
            loading={isLoading}
            pageSizeOptions={[10, 20, 50, 100]}
            rowCount={numTotalItems}
            paginationMode="server"
            paginationModel={{ pageSize: rowsPerPage, page: page }}
            onPaginationModelChange={({ page, pageSize }) => {
              setPage(page);
              setRowsPerPage(pageSize);
            }}
            sx={{
              backgroundColor: '#fff',
              '.MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold !important',
                overflow: 'visible !important',
              },
              '& .MuiDataGrid-virtualScroller': {
                overflowX: 'auto !important',
              },
              '& .MuiDataGrid-virtualScroller::-webkit-scrollbar': {
                height: numTotalItems ? '8px' : 0,
              },
              '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb': {
                backgroundColor: grey[600],
                borderRadius: '10px',
              },
              '& .MuiDataGrid-virtualScroller::-webkit-scrollbar-thumb:hover': {
                backgroundColor: grey[700],
              }
            }}
            disableRowSelectionOnClick
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            slots={{
              toolbar: (props) =>
                <CustomToolbarReports
                  reportName="relatorio_retiradas_"
                  columns={columns}
                  pdfExport={exportDataToPDF}
                  props={props} />
              ,
              loadingOverlay: LinearProgress,
              noRowsOverlay: () => (
                <Box style={{ display: "flex", width: '100%', textAlign: 'center', height: "100%", alignItems: 'center', justifyContent: 'center' }}>
                  {isSearched ?
                    <Typography variant='h6' color='textSecondary'>Nenhum registro encontrado para o filtro selecionado</Typography>
                    :
                    <Typography variant='h6' color='textSecondary'>Selecione uma empresa e o período que deseja filtrar</Typography>
                  }
                </Box>
              )
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}