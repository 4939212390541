import { useCallback, useEffect, useMemo, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import {
  Box,
  Breadcrumbs,
  Button,
  CircularProgress,
  Grid,
  LinearProgress,
  MenuItem,
  TextField,
  Typography
} from '@mui/material';
import { DataGrid, ptBR } from '@mui/x-data-grid';
import { FilterAlt, Home as HomeIcon } from '@mui/icons-material';
import { VendingMachineIcon } from '../../../common/Icons';
import PageTitle from '../../../common/PageTitle';
import LinkNavegacao from '../../../common/Link';
import { useAppContext } from '../../../../contexts/AppContext';
import { checkPermissionsAndRedirect, getFormErrorMessage, verifyFieldsAreFilled } from '../../../../configs/functions';
import { exportBalanceMachineToPDF } from './configs/utils';
import { getSaldoMaquinaData } from './configs/functions';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import { carregaEquipamentos } from '../equipments/configs/functions';
import { CustomToolbarReports } from '../../../common/CustomToolbarReports';
import { listaEmpresas } from '../../management/companies/configs/functions';

export function SaldosMaquina() {
  const { exibirAlerta } = useCommonItems();
  const { dadosUsuario } = useAppContext();

  // estados para controle de paginacao
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(100);
  const [numTotalItems, setNumTotalItems] = useState(0);

  const [balanceMachine, setDataBalanceMachine] = useState([]);
  const [maquinas, setMaquinas] = useState([]);
  const [empresas, setEmpresas] = useState([]);

  const [isLoading, setIsLoading] = useState(false);
  const [isSearched, setIsSearched] = useState(false);
  const [selectedEquipment, setSelectedEquipment] = useState(null);
  const [colCount, setColCount] = useState(10);

  const {
    handleSubmit,
    setValue,
    getValues,
    clearErrors,
    reset,
    control,
    formState: { errors },
  } = useForm();

  // simula agrupamento por bandeja
  const groupedRows = useMemo(() => {
    const groups = {};
    balanceMachine.forEach((item) => {
      const numMotor = isNaN(item.numero_motor)
        ? parseInt(item.numero_motor.split('-')[0])
        : item.numero_motor;

      const bandeja = numMotor % colCount === 0
        ? Math.floor(numMotor / colCount)
        : Math.floor(numMotor / colCount) + 1;

      if (!groups[bandeja]) {
        groups[bandeja] = [];
      }
      groups[bandeja].push(item);
    });

    // Retornar os dados em um formato que o DataGrid possa renderizar
    return Object.entries(groups).flatMap(([bandeja, items]) => [
      { id: `bandeja-${bandeja}`, isTray: true, bandeja },
      ...items.map((item) => ({ ...item, id: `motor-${item.numero_motor}` })),
    ]);
  }, [balanceMachine, colCount]);

  const columns = useMemo(() => [
    {
      field: "bandeja",
      headerName: "Bandeja",
      sortable: false,
      minWidth: 110,
      flex: 0.5,
      renderCell: (params) => {
        if (params.row.isTray) {
          return (
            <Typography variant="h3" fontWeight="bold">
              Bandeja {params.row.bandeja}
            </Typography>
          );
        }
        return <Typography>-</Typography>;
      },
    },
    {
      field: 'numero_motor',
      headerName: 'Motor(es)',
      sortable: false,
      minWidth: 150,
      flex: 0.5,
      renderCell: (params) => {
        if (params.row.isTray) {
          return null;
        }
        return <Typography variant="body2" color={!params.value && 'textSecondary'}>
          {String(params.value) || 'Vazio'}
        </Typography>;
      },
    },
    {
      field: 'codigo',
      headerName: 'Código',
      sortable: false,
      minWidth: 180,
      flex: 1,
      renderCell: (params) => {
        if (params.row.isTray) {
          return null;
        }
        return <Typography variant="body2" color={!params.value && 'textSecondary'}>{params.value || 'Vazio'}</Typography>;
      },
    },
    {
      field: 'nome',
      headerName: 'Nome',
      sortable: false,
      minWidth: 300,
      flex: 2,
      renderCell: (params) => {
        if (params.row.isTray) {
          return null;
        }
        return <Typography variant="body2" color={!params.value && 'textSecondary'}>{params.value || 'Vazio'}</Typography>;
      },
    },
    {
      field: 'categoria',
      headerName: 'Categoria',
      sortable: false,
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        if (params.row.isTray) {
          return null;
        }
        return <Typography variant="body2" color={!params.value && 'textSecondary'}>{params.value || 'Vazio'}</Typography>;
      },
    },
    {
      field: 'unidade_medida',
      headerName: 'Unidade Medida',
      sortable: false,
      minWidth: 200,
      flex: 1,
      renderCell: (params) => {
        if (params.row.isTray) {
          return null;
        }
        return <Typography variant="body2" color={!params.value && 'textSecondary'}>{params.value || 'Vazio'}</Typography>;
      },
    },
    {
      field: 'quantidade',
      headerName: 'Quantidade',
      sortable: false,
      minWidth: 150,
      flex: 0.5,
      renderCell: (params) => {
        if (params.row.isTray) {
          return null;
        }
        return <Typography variant="body2" color={!params.value && 'textSecondary'}>{params.value || '0'}</Typography>;
      },
    },
    {
      field: 'capacidade',
      headerName: 'Capacidade',
      sortable: false,
      minWidth: 150,
      flex: 0.5,
      renderCell: (params) => {
        if (params.row.isTray) {
          return null;
        }
        return <Typography variant="body2" color={!params.value && 'textSecondary'}>{params.value || '0'}</Typography>;
      },
    }
  ], []);

  async function loadReportData(id_equipamento) {
    if (!id_equipamento) return;

    try {
      setIsLoading(true);
      const filter = {
        id_equipamento: id_equipamento,
        limit: rowsPerPage,
        offset: page * rowsPerPage,
      };
      const res = await getSaldoMaquinaData(filter);
      setDataBalanceMachine(res.data.resultados);
      setNumTotalItems(res.data.numero_total);
    } catch (error) {
      console.log(error);
      exibirAlerta('Ops', 'Ocorreu um erro ao carregar dados do relatório.', 'error');
    } finally {
      setIsLoading(false);
    }
  };

  const onSubmit = async (data) => {
    try {
      setIsSearched(true);
      loadReportData(data.id_equipamento);
    } catch (error) {
      exibirAlerta('Erro', 'Erro ao carregar dados do relatório', 'error');
      setIsSearched(false);
    }
  };

  const getMachines = useCallback(async (id_equipamento) => {
    try {
      const response = await carregaEquipamentos({ tipo: "maquina" });
      setMaquinas(response.data.data);
    } catch (error) {
      exibirAlerta('Erro ao carregar as Máquinas', '', 'error');
    } finally {
      setIsLoading(false);
    }
  }, [exibirAlerta]);

  const getEmpresasData = useCallback(async () => {
    try {
      const response = await listaEmpresas();
      setEmpresas(response.data.data);
    } catch (error) {
      exibirAlerta('Erro ao carregar as Empresas', '', 'error');
    }
  }, [exibirAlerta]);

  useEffect(() => {
    const requiredPermissionsView = ["admin", "admin_relatorio"];
    checkPermissionsAndRedirect(requiredPermissionsView);

    getMachines();
    getEmpresasData();

    return () => {
      reset();
    }
  }, [getEmpresasData, getMachines, reset]);

  useEffect(() => {
    const id_equipamento = getValues('id_equipamento');

    if (!id_equipamento) {
      return;
    }

    loadReportData(id_equipamento);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, rowsPerPage]);

  useEffect(() => {
    if (selectedEquipment) {
      setColCount(selectedEquipment.qtd_motores === 60 ? 10 : 6);
    }
  }, [selectedEquipment]);

  function exportDataToPDF() {
    if (!getValues('id_equipamento')) {
      return exibirAlerta('Ops', 'Selecione uma máquina', 'warning');
    }

    const selectedMachine = maquinas.find(item => item.id === getValues('id_equipamento'));
    const selectedEmpresaId = selectedMachine?.empresa?.id || null;
    const selectedEmpresa = empresas.find(item => item.id === selectedEmpresaId);

    exportBalanceMachineToPDF({
      tableData: groupedRows,
      selectedMaquina: selectedMachine,
      selectedEmpresa: selectedEmpresa,
      dadosUsuario
    })
  }

  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-start"
      alignItems="stretch"
      spacing={3}
    >
      <Grid item xs={12}>
        <Breadcrumbs>
          <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao>
          <LinkNavegacao to='/epis'>Gestão de EPI's</LinkNavegacao>
          <LinkNavegacao to='/epis/relatorios'>Relatórios</LinkNavegacao>
          <Typography variant='span'>Saldo de Máquinas</Typography>
        </Breadcrumbs>
        <PageTitle icon={<VendingMachineIcon fontSize='large' />} title="Saldo de Máquinas" />
      </Grid>

      <Grid container item xs={12} spacing={3} component="form" onSubmit={handleSubmit(onSubmit)}>
        <Grid item xs={12} md={6}>
          <Controller
            name='id_equipamento'
            control={control}
            rules={{ required: false }}
            defaultValue={''}
            render={({ field: { ref, onChange, ...field } }) => (
              <TextField
                {...field}
                select
                fullWidth
                label="Máquina"
                onChange={(e) => {
                  setIsSearched(false);
                  setSelectedEquipment(maquinas.find(item => item.id === e.target.value));
                  setValue('id_equipamento', e.target.value, { shouldDirty: true })
                  if (e.target.value) {
                    clearErrors("id_equipamento");
                  }
                }}
                error={!!errors.id_equipamento}
                helperText={getFormErrorMessage(errors, 'id_equipamento')}
                size='small'
                InputProps={{ style: { backgroundColor: '#fff' } }}
              >
                {maquinas.length > 0 ? (
                  [
                    ...maquinas.filter(option => option.status !== 0).map(option => (
                      <MenuItem key={option.id} value={option.id}>
                        {option.nome} - {option.tag_identificacao} ({option.qtd_motores} motores)
                      </MenuItem>
                    )),
                    ...maquinas.filter(option => option.status === 0).map(option => (
                      <MenuItem key={option.id} value={option.id} disabled>
                        {option.nome} - {option.tag_identificacao} ({option.qtd_motores} motores) - (Inativo)
                      </MenuItem>
                    ))
                  ]
                ) : (
                  <MenuItem disabled>Nenhum disponível</MenuItem>
                )}
              </TextField>
            )}
          />
        </Grid>

        <Grid item xs={12} md={1}>
          <Button
            type='submit'
            color='primary'
            variant='contained'
            disabled={isLoading || Object.keys(errors).length > 0 || !verifyFieldsAreFilled([getValues('id_equipamento')])}
            startIcon={isLoading ? <CircularProgress size={16} sx={{ color: "textSecondary" }} /> : <FilterAlt />}
          >
            Filtrar
          </Button>
        </Grid>
      </Grid>

      <Grid item xs={12}>
        <Box sx={{
          height: balanceMachine.length > 0 ? "auto" : 400,
          minHeight: 400,
          width: '100%',
          '& .MuiDataGrid-columnHeaders, .MuiDataGrid-toolbarContainer': {
            backgroundColor: '#d8d8d8',
          },
          '& .MuiDataGrid-columnHeaders': {
            borderRadius: 0
          },
        }}>
          <DataGrid
            rows={groupedRows}
            columns={columns}
            getRowClassName={(params) =>
              params.row.isTray ? 'group-row' : ''
            }
            loading={isLoading}
            pageSizeOptions={[1, 3, 10, 20, 50, 100]}
            rowCount={numTotalItems}
            paginationMode="server"
            paginationModel={{ pageSize: rowsPerPage, page: page }}
            onPaginationModelChange={({ page, pageSize }) => {
              setPage(page);
              setRowsPerPage(pageSize);
            }}
            sx={{
              '& .group-row': {
                backgroundColor: '#f0f0f0',
                fontWeight: 'bold',
              },
              backgroundColor: '#fff',
              '.MuiDataGrid-columnHeaderTitle': {
                fontWeight: 'bold !important',
                overflow: 'visible !important',
              }
            }}
            disableRowSelectionOnClick
            localeText={ptBR.components.MuiDataGrid.defaultProps.localeText}
            slots={{
              toolbar: (props) =>
                <CustomToolbarReports
                  reportName="relatorio_saldo_maquina_"
                  columns={columns}
                  pdfExport={exportDataToPDF}
                  props={props}
                />,
              loadingOverlay: LinearProgress,
              noRowsOverlay: () => (
                <Box style={{ display: "flex", width: '100%', textAlign: 'center', height: "100%", alignItems: 'center', justifyContent: 'center' }}>
                  {isSearched ?
                    <Typography variant='h6' color='textSecondary'>Nenhum registro encontrado para o filtro selecionado</Typography>
                    :
                    <Typography variant='h6' color='textSecondary'>Selecione o equipamento que deseja filtrar</Typography>
                  }
                </Box>
              )
            }}
          />
        </Box>
      </Grid>
    </Grid>
  );
}