import { useEffect } from 'react';
import { Breadcrumbs, Grid, Typography } from '@mui/material';
import { Home as HomeIcon } from '@mui/icons-material';
import PageTitle from '../../common/PageTitle';
import { SettingsIcon } from '../../common/Icons';
import LinkNavegacao from '../../common/Link';
import CardOption from '../../common/CardOption';
import { checkPermissionsAndRedirectAdmin } from '../../../configs/functions';

export function OrganizationSempher() {
    useEffect(() => {
        checkPermissionsAndRedirectAdmin();
    }, []);

    return (
        <Grid
            container
            direction="row"
            justifyContent="flex-start"
            alignItems="stretch"
            spacing={2}
        >
            <Grid item xs={12}>
                <Breadcrumbs>
                    <LinkNavegacao to='/'><HomeIcon fontSize='small' /></LinkNavegacao>
                    <Typography variant='span'>Gerenciamento Admin</Typography>
                    <Typography variant='span'>Organização</Typography>
                </Breadcrumbs>
                <PageTitle icon={<SettingsIcon fontSize='large' />} title='Organização' />
            </Grid>

            <Grid item xs={12}>
                <Typography variant='h6' mt={1}>
                    Administre a organização do sistema.
                </Typography>
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption
                    title="Cargos Padronizados (Roles)"
                    description="Gerencie os cargos disponíveis para os usuários."
                    url='roles'
                />
            </Grid>

            <Grid item xs={12} sm={6} md={4} lg={4} xl={3}>
                <CardOption
                    title="Permissões (Abilities)"
                    description="Gerencie as habilidades e suas dependências."
                    url='abilities'
                />
            </Grid>
        </Grid>
    );
}