import axios from "axios";
import { urlHabilidades, urlRoles } from '../../../../constants/endpoints';

// abilities
export const listaHabilidades = (filter = null) => axios.get(urlHabilidades, { params: filter });
export const cadastraHabilidade = (data) => axios.post(`${urlHabilidades}`, data);
export const atualizaHabilidade = (id, data) => axios.patch(`${urlHabilidades}/${id}`, data);
export const manipulaHabilidade = (data, isEditAction) => isEditAction ? atualizaHabilidade(data.id, data) : cadastraHabilidade(data);
export const deletaHabilidade = (id) => axios.delete(`${urlHabilidades}/${id}`);

// roles
export const listaRoles = (filter = null) => axios.get(urlRoles, { params: filter });
export const deletaRole = (id) => axios.delete(`${urlRoles}/${id}`);