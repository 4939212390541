import dayjs from 'dayjs';
import jsPDF from "jspdf";
import "jspdf-autotable";
import FileSaver from "file-saver";
import * as XLSX from "xlsx";
import Logotipo from "../../../../../assets/images/stw_logo_preto.png";
import { maskCNPJ } from '../../../../../configs/functions';
import { Info } from '@mui/icons-material';
import { ClientManagementIcon, GoodPincodeIcon, NameTagIcon } from '../../../../common/Icons';

const marginLeft = 40;

function addLineDivider(doc, marginLeft, yPosition) {
  doc.setLineWidth(1);
  doc.setDrawColor(204, 204, 204);
  doc.line(marginLeft, yPosition, 800, yPosition);
}

function addFooter(doc, data, user) {
  const nome = user?.nome || 'Não informado';
  const matricula = user?.matricula || 'Não informada';

  const maxLengthNameInFooter = 25;
  const displayNomeInFooter = nome.length > maxLengthNameInFooter ? nome.substring(0, maxLengthNameInFooter) + '...' : nome;
  const pageHeight = doc.internal.pageSize.height || doc.internal.pageSize.getHeight();
  let pageNumber = `Página ${data.pageNumber}`;
  let generatedInfo = `Relatório gerado por: ${displayNomeInFooter}, Matrícula: ${matricula} - ${dayjs().format('DD/MM/YYYY HH:mm:ss')}`;

  doc.setFontSize(8);
  doc.setTextColor(100, 100, 100)

  doc.text(pageNumber, data.settings.margin.left, pageHeight - 20);
  doc.text(generatedInfo, data.settings.margin.left + 475, pageHeight - 20);
}

function headerReport(doc, empresa, reportName, borderBottomWidth) {
  doc.setFont('helvetica', 'bold');

  const imgWidth = 100;
  const imgHeight = 50;

  const logoEmpresa = empresa?.logo || Logotipo;
  doc.addImage(logoEmpresa, 'PNG', marginLeft, 20, imgWidth, imgHeight);

  doc.setFontSize(13);
  doc.text(reportName, (1.3 * marginLeft) + imgWidth, 40);

  // LINE DIVIDER
  doc.setLineWidth(1);
  doc.setDrawColor(204, 204, 204);
  doc.line((1.3 * marginLeft) + imgWidth, 45, ((1.3 * marginLeft) + imgWidth) + borderBottomWidth, 45);  // Draw line (x1, y1, x2, y2)

  doc.setFont('helvetica', 'normal');
  doc.setFontSize(10);
  doc.text("Sistema de Controle de Entrega de EPIs", (1.3 * marginLeft) + imgWidth, 60);

  doc.setFont('helvetica', 'bold');
  doc.text("Empresa:", marginLeft, 90);
  doc.setFont('helvetica', 'normal');
  doc.text(empresa.nomeFantasia, marginLeft + 50, 90);

  doc.setFont('helvetica', 'bold');
  doc.text("CNPJ:", marginLeft + 630, 90);
  doc.setFont('helvetica', 'normal');
  doc.text(maskCNPJ(empresa.cnpj), marginLeft + 665, 90);
}

export function exportFichaEPIToPDF(dataToExport) {
  const tableData = dataToExport.tableData
  const empresa = dataToExport.selectEmpresa
  const user = dataToExport.dadosUsuario.pessoa;
  const colaborador = dataToExport.colaborador;

  const unit = "pt";
  const size = "A4";
  const orientation = "landscape";
  const marginSecondColumn = 440;

  const doc = new jsPDF(orientation, unit, size);

  const headers = [["CA", "Produto", "Qtd Produto", "Data", "Função Colaborador", "Responsável Entrega", "Ponto Entrega"]];
  const formattedData = tableData.map(elt => [
    elt.ca || 'Não informado',
    elt.produto || 'Não informado',
    elt.qtd_produto || 'Não informado',
    elt.data || 'Não informado',
    elt.nomeFuncao || 'Não informado',
    elt.responsavel_entrega || 'Não informado',
    elt.ponto_entrega || 'Não informado',
  ]);

  const text = `Pelo presente termo de responsabilidade pela guarda e uso dos Equipamentos de Proteção Individual - EPI abaixo discriminados, eu:
  1- Declaro haver sido submetido a treinamento quanto ao uso, conservação, higienização e guarda do EPI, responsabilizando-se por isso e comprometendo-se a utilizá-lo apenas para a finalidade a que se destina;
  2 - Me comprometo a comunicar de imediato a empresa acerca de qualquer alteração que torne o EPI impróprio para o uso;
  3 - Declaro estar ciente de que o EPI é de propriedade da empresa, comprometendo-se a devolvê-lo em caso de transferência, demissão ou alteração para cargo em que sua utilização se torne desnecessária;
  4 - Autorizo a empresa a efetuar em meus vencimentos os descontos correspondentes ao valor do EPI ora entregue em caso de extravio ou dano causado ao mesmo por uso indevido ou má conservação;
  5 - Declaro ainda ter ciência de que a recusa injustificada ao uso do EPI fornecido pela empresa constitui falta grave, podendo inclusive ser punida com a justa causa.`;

  headerReport(doc, empresa, "COMPROVANTE DE FORNECIMENTO DE EPI", 280);

  // DADOS DO COLABORADOR (FICHA EPI)
  const nomeColaborador = colaborador?.nome || 'Não informado';
  const matriculaColaborador = colaborador?.matricula || 'Não informada';
  const funcaoColaborador = colaborador?.funcao?.nome || 'Não informada';
  const setorColaborador = colaborador?.setor?.nome || 'Não informado';

  doc.setFont('helvetica', 'bold');
  doc.text("Dados do Colaborador:", marginLeft, 120);
  doc.setFont('helvetica', 'normal');

  addLineDivider(doc, marginLeft, 132);

  doc.setFont('helvetica', 'bold');
  doc.text("Nome:", marginLeft, 150);
  doc.setFont('helvetica', 'normal');
  doc.text(nomeColaborador, marginLeft + 35, 150);

  doc.setFont('helvetica', 'bold');
  doc.text("Matrícula:", marginLeft + marginSecondColumn, 150);
  doc.setFont('helvetica', 'normal');
  doc.text(matriculaColaborador, marginLeft + marginSecondColumn + 50, 150);

  doc.setFont('helvetica', 'bold');
  doc.text("Função Atual:", marginLeft, 170);
  doc.setFont('helvetica', 'normal');
  doc.text(funcaoColaborador, marginLeft + 70, 170);

  doc.setFont('helvetica', 'bold');
  doc.text("Setor:", marginLeft + marginSecondColumn, 170);
  doc.setFont('helvetica', 'normal');
  doc.text(setorColaborador, marginLeft + marginSecondColumn + 33, 170);

  addLineDivider(doc, marginLeft, 182);

  doc.setFont('helvetica', 'normal');
  doc.setTextColor(100, 100, 100);
  doc.text(text, marginLeft, 205, { maxWidth: 750 });

  doc.setFontSize(15);
  doc.setTextColor(0, 0, 0);

  const tableContent = {
    head: headers,
    body: formattedData,
    startY: 312,
    theme: 'grid',
    fillColor: [255, 0, 0],
    headStyles: {
      fillColor: [30, 81, 57]
    },
    didDrawPage: function (data) {
      addFooter(doc, data, user);
    }
  };

  doc.autoTable(tableContent);
  doc.save(`relatorio_ficha_epi_${dayjs().format('YYYY-MM-DD_HHmm')}.pdf`);
}

export function exportFichaEPIEmpresaToPDF(dataToExport) {
  const tableData = dataToExport.tableData;
  const empresa = dataToExport.selectedEmpresa;
  const user = dataToExport.dadosUsuario.pessoa;
  const dataInicio = dataToExport.dataInicio;
  const dataFim = dataToExport.dataFim;

  const unit = "pt";
  const size = "A4";
  const orientation = "landscape";
  const marginLeft = 40;
  const marginSecondColumn = 440;

  const doc = new jsPDF(orientation, unit, size);

  const headers = [["Produto", "CA", "Quantidade", "Função", "Responsável", "Data Retirada"]];

  headerReport(doc, empresa, "COMPROVANTE DE FORNECIMENTO DE EPI", 280);

  // percorrer cada responsável
  tableData.forEach((colaborador, index) => {
    const isFirst = index === 0;

    if (!isFirst) {
      doc.addPage();
    }

    const startY = isFirst ? 110 : 30;

    const nomeColaborador = colaborador.usuario.nome || 'Não informado';
    const matriculaColaborador = colaborador.usuario.matricula || 'Não informado';
    const nomeFuncaoColaborador = colaborador.usuario.funcao || 'Não informado';
    const tituloSetorColaborador = colaborador.usuario.setor || 'Não informado';
    const quantidadeTotalColaborador = colaborador.usuario.quantidadeTotalColaborador || 'Não informado';

    doc.setFont('helvetica', 'bold');
    doc.text("Dados do Colaborador:", marginLeft, startY);
    doc.setFont('helvetica', 'normal');

    addLineDivider(doc, marginLeft, startY + 12);

    // Dados do Colaborador
    doc.setFont('helvetica', 'bold');
    doc.text(`Nome:`, marginLeft, startY + 32);
    doc.setFont('helvetica', 'normal');
    doc.text(nomeColaborador, marginLeft + 35, startY + 32);

    doc.setFont('helvetica', 'bold');
    doc.text("Matrícula:", marginLeft + marginSecondColumn, startY + 32);
    doc.setFont('helvetica', 'normal');
    doc.text(matriculaColaborador.toString(), marginLeft + marginSecondColumn + 50, startY + 32);

    doc.setFont('helvetica', 'bold');
    doc.text("Função:", marginLeft, startY + 52);
    doc.setFont('helvetica', 'normal');
    doc.text(nomeFuncaoColaborador, marginLeft + 43, startY + 52);

    doc.setFont('helvetica', 'bold');
    doc.text("Setor:", marginLeft + marginSecondColumn, startY + 52);
    doc.setFont('helvetica', 'normal');
    doc.text(tituloSetorColaborador, marginLeft + marginSecondColumn + 35, startY + 52);

    doc.setFont('helvetica', 'bold');
    doc.text("Quantidade Total de Produtos Retirados:", marginLeft, startY + 72);
    doc.setFont('helvetica', 'normal');
    doc.text(quantidadeTotalColaborador.toString(), marginLeft + 200, startY + 72);

    doc.setFont('helvetica', 'bold');
    doc.text('Período Analisado:', marginLeft + marginSecondColumn, startY + 72);
    doc.setFont('helvetica', 'normal');
    doc.text(`${dataInicio} - ${dataFim}`, marginLeft + marginSecondColumn + 95, startY + 72);

    addLineDivider(doc, marginLeft, startY + 82);

    const formattedData = colaborador.retiradas.map(retirada => [
      retirada.produto || 'Não informado',
      retirada.ca || 'Não informado',
      retirada.qtd_produto || 'Não informado',
      retirada.nomeFuncao || 'Não informado',
      retirada.responsavel_entrega || 'Não informado',
      retirada.data || 'Não informado',
    ]);

    const tableContent = {
      head: headers,
      body: formattedData,
      startY: startY + 92,
      theme: 'grid',
      headStyles: {
        fillColor: [30, 81, 57]
      },
      didDrawPage: function (data) {
        const pageNumber = doc.internal.getCurrentPageInfo().pageNumber;
        data.pageNumber = pageNumber;
        addFooter(doc, data, user);
      }
    };

    doc.autoTable(tableContent);
  });

  // caso não tenha nenhum colaborador
  if (tableData.length === 0) {
    doc.setFont('helvetica', 'bold');
    doc.setFontSize(11);
    doc.text("Nenhuma retirada foi registrada para o período selecionado.", marginLeft, 120);
    doc.setFont('helvetica', 'normal');
    doc.text(`(${dataInicio} - ${dataFim})`, marginLeft, 135);
  }

  doc.save(`relatorio_ficha_epi_empresa_${empresa.nomeFantasia}_${dayjs().format('YYYY-MM-DD_HHmm')}.pdf`);
}

export function exportSupplyMachineToPDF(dataToExport) {
  const tableData = dataToExport.tableData;
  const empresa = dataToExport.selectEmpresa;
  const user = dataToExport.dadosUsuario.pessoa;

  const unit = "pt";
  const size = "A4";
  const orientation = "landscape";

  const doc = new jsPDF(orientation, unit, size);

  const headers = [["ÁREA", "SETOR", "MÁQUINA", "ENDEREÇO", "MOTOR", "PRODUTO", "ABASTECEDOR", "REPOSIÇÃO", "STR", "DATA"]];
  const formattedData = tableData.map(elt => [
    elt.area || "Não informado",
    elt.setor || "Não informado",
    elt.maquina || "Não informado",
    elt.endereco || "Não informado",
    elt.motor || "Não informado",
    elt.produto || "Não informado",
    elt.abastecedor || "Não informado",
    elt.reposição || "Não informado",
    elt.str || "Não informado",
    elt.data || "Não informado",
  ]);

  headerReport(doc, empresa, "RELATÓRIO DE ABASTECIMENTO DE MÁQUINA", 305);

  addLineDivider(doc, marginLeft, 130);

  doc.setFontSize(15);
  doc.setTextColor(0, 0, 0);

  const tableContent = {
    head: headers,
    body: formattedData,
    startY: 140,
    theme: 'grid',
    fillColor: [255, 0, 0],
    headStyles: {
      fillColor: [30, 81, 57]
    },
    // sum of columnWidths must be equal to 760
    columnStyles: {
      0: { columnWidth: 80 },  // ÁREA
      1: { columnWidth: 80 },  // SETOR
      2: { columnWidth: 100 }, // MÁQUINA
      3: { columnWidth: 80 },  // ENDEREÇO
      4: { columnWidth: 55 },  // MOTOR
      5: { columnWidth: 90 },  // PRODUTO
      6: { columnWidth: 80 },  // ABASTECEDOR
      7: { columnWidth: 60 },  // REPOSIÇÃO
      8: { columnWidth: 70 },  // STR
      9: { columnWidth: 65 },  // DATA
    },
    didDrawPage: function (data) {
      addFooter(doc, data, user);
    }
  };

  doc.autoTable(tableContent);
  doc.save(`relatorio_abastecimento_maquina_${dayjs().format('YYYY-MM-DD_HHmm')}.pdf`);
}

export function exportBalanceMachineToPDF(dataToExport) {
  const tableData = dataToExport.tableData;
  const maquina = dataToExport.selectedMaquina;
  const empresa = dataToExport.selectedEmpresa;
  const user = dataToExport.dadosUsuario.pessoa;

  const unit = "pt";
  const size = "A4";
  const orientation = "landscape";

  const doc = new jsPDF(orientation, unit, size);

  const headers = [["Bandeja", "Nº Motor", "Código", "Nome", "Quantidade", "Categoria", "Unidade", "Capacidade"]];

  const formattedData = tableData.map(item => {
    if (item?.isTray) {
      return [
        `Bandeja ${item.bandeja}`, '', '', '', '', '', '', '', ''
      ];
    } else {
      return [
        item?.bandeja || '',
        item?.numero_motor || '-',
        item?.codigo || '-',
        item?.nome || '-',
        item?.quantidade || 0,
        item?.categoria || '-',
        item?.unidade_medida || '-',
        item?.capacidade ? String(item.capacidade) : 0,
      ];
    }
  });


  headerReport(doc, empresa, `RELATÓRIO DE SALDO DE MÁQUINA: ${maquina?.nome}`, 380);

  doc.setFont('helvetica', 'bold');
  doc.text("Máquina Selecionada:", marginLeft, 110);
  doc.setFont('helvetica', 'normal');

  addLineDivider(doc, marginLeft, 120);

  const maquinaNome = maquina?.nome || 'Não informado';
  const maquinaTag = maquina?.tag_identificacao || 'Não informado';
  const maquinaDescricao = maquina?.descricao || 'Não informado';
  const maquinaQtdMotores = maquina?.qtd_motores ? String(maquina.qtd_motores) : 'Não informado';
  const maquinaLastSyncAt = maquina?.lastSyncAt || 'Não informado';
  const maquinaTipoContrato = maquina?.tipo_contrato || 'Não informado';

  doc.setFont('helvetica', 'bold');
  doc.text("Maquina:", marginLeft, 140);
  doc.setFont('helvetica', 'normal');
  doc.text(maquinaNome, marginLeft + 50, 140);

  doc.setFont('helvetica', 'bold');
  doc.text("Tag Identificação:", marginLeft + 580, 140);
  doc.setFont('helvetica', 'normal');
  doc.text(maquinaTag, marginLeft + 670, 140);

  doc.setFont('helvetica', 'bold');
  doc.text("Descrição:", marginLeft, 160);
  doc.setFont('helvetica', 'normal');
  doc.text(maquinaDescricao, marginLeft + 55, 160);

  doc.setFont('helvetica', 'bold');
  doc.text("Quantidade Motores:", marginLeft + 580, 160);
  doc.setFont('helvetica', 'normal');
  doc.text(maquinaQtdMotores, marginLeft + 685, 160);

  doc.setFont('helvetica', 'bold');
  doc.text("Última Sincronização:", marginLeft, 180);
  doc.setFont('helvetica', 'normal');
  doc.text(maquinaLastSyncAt, marginLeft + 110, 180);

  doc.setFont('helvetica', 'bold');
  doc.text("Tipo Contrato:", marginLeft + 580, 180);
  doc.setFont('helvetica', 'normal');
  doc.text(maquinaTipoContrato, marginLeft + 655, 180);

  addLineDivider(doc, marginLeft, 195);

  doc.setFontSize(15);
  doc.setTextColor(0, 0, 0);

  const tableContent = {
    head: headers,
    body: formattedData,
    startY: 210,
    theme: 'grid',
    fillColor: [255, 0, 0],
    headStyles: {
      fillColor: [30, 81, 57]
    },
    didDrawPage: function (data) {
      const pageNumber = doc.internal.getCurrentPageInfo().pageNumber;
      data.pageNumber = pageNumber;
      addFooter(doc, data, user);
    }
  };
  doc.autoTable(tableContent);
  doc.save(`relatorio_saldo_maquina_${maquina?.nome}_${dayjs().format('YYYY-MM-DD_HHmm')}.pdf`);
}

export function exportRetiradasToPDF(dataToExport) {
  const tableData = dataToExport.tableData
  const empresa = dataToExport.selectEmpresa
  const user = dataToExport.dadosUsuario.pessoa;

  const unit = "pt";
  const size = "A4";
  const orientation = "landscape";

  const doc = new jsPDF(orientation, unit, size);

  const headers = [["Empresa", "Setor", "Área", "Grupo", "Função", "Equipamento", "Autenticação", "Funcionário", "Responsável Entrega", "CA", "Produto", "Quantidade", "Data Retirada"]];

  const formattedData = tableData.map(elt => [
    elt.nomeEmpresa || 'Não informado',
    elt.nomeSetor || 'Não informado',
    elt.nomeArea || 'Não informado',
    elt.nomeGrupo || 'Não informado',
    elt.nomeFunção || 'Não informado',
    elt.nomeEquipamento || 'Não informado',
    elt.tipoAutenticacao || 'Não informado',
    elt.nomeFuncionario || 'Não informado',
    elt.responsavelEntrega || 'Não informado',
    elt.produtoCA || 'Não informado',
    elt.produto || 'Não informado',
    elt.quantidade || 'Não informado',
    elt.dataRetirada || 'Não informado',
  ]);

  headerReport(doc, empresa, "RELATÓRIO DE RETIRADAS", 180);

  addLineDivider(doc, marginLeft, 100);

  doc.setFontSize(15);
  doc.setTextColor(0, 0, 0);

  const tableContent = {
    head: headers,
    body: formattedData,
    startY: 110,
    theme: 'grid',
    fillColor: [255, 0, 0],
    headStyles: {
      fillColor: [30, 81, 57]
    },
    didDrawPage: function (data) {
      addFooter(doc, data, user);
    }
  };
  doc.autoTable(tableContent);
  doc.save(`relatorio_retiradas_${empresa.nomeFantasia}_${dayjs().format('YYYY-MM-DD_HHmm')}.pdf`);
}

export function exportPendenciasRetiradasToPDF(dataToExport) {
  const tableData = dataToExport.tableData
  const empresa = dataToExport.selectEmpresa
  const user = dataToExport.dadosUsuario.pessoa;

  const unit = "pt";
  const size = "A4";
  const orientation = "landscape";

  const doc = new jsPDF(orientation, unit, size);

  const headers = [["Funcionário", "Empresa", "Setor", "Área", "Grupo", "Função", "CA", "Produto", "Dias Pendentes", "Data Próxima Retirada", "Data Retirada"]];

  const formattedData = tableData.map(elt => [
    elt.nomeFuncionario || 'Não informado',
    elt.nomeEmpresa || 'Não informado',
    elt.nomeFuncionario || 'Não informado',
    elt.nomeEmpresa || 'Não informado',
    elt.tituloSetor || 'Não informado',
    elt.nomeArea || 'Não informado',
    elt.nomeGrupo || 'Não informado',
    elt.nomeFuncao || 'Não informado',
    elt.produtoCA || 'Não informado',
    elt.nomeProduto || 'Não informado',
    elt.diasPendentes || 'Não informado',
    elt.dataProximaRetirada || 'Não informado',
    elt.dataRetirada || 'Não informado',
  ]);

  headerReport(doc, empresa, "RELATÓRIO DE PENDÊNCIAS DE RETIRADAS", 290);

  addLineDivider(doc, marginLeft, 100);

  doc.setFontSize(15);
  doc.setTextColor(0, 0, 0);

  const tableContent = {
    head: headers,
    body: formattedData,
    startY: 110,
    theme: 'grid',
    fillColor: [255, 0, 0],
    headStyles: {
      fillColor: [30, 81, 57]
    },
    didDrawPage: function (data) {
      addFooter(doc, data, user);
    }
  };
  doc.autoTable(tableContent);
  doc.save(`relatorio_pendencias_retiradas_${empresa.nomeFantasia}_${dayjs().format('YYYY-MM-DD_HHmm')}.pdf`);
}

export function exportRetiradasExtrasToPDF(dataToExport) {
  const tableData = dataToExport.tableData
  const empresa = dataToExport.selectEmpresa
  const user = dataToExport.dadosUsuario.pessoa;

  const unit = "pt";
  const size = "A4";
  const orientation = "landscape";

  const doc = new jsPDF(orientation, unit, size);

  const headers = [["Empresa", "Setor", "Área", "Grupo", "Função", "Equipamento", "Funcionário", "Responsável Entrega", "Produto", "Justificativa", "Data"]];
  const formattedData = tableData.map(elt => [
    elt.nomeEmpresa || 'Não informado',
    elt.nomeSetor || 'Não informado',
    elt.nomeArea || 'Não informado',
    elt.nomeGrupo || 'Não informado',
    elt.nomeFunção || 'Não informado',
    elt.nomeEquipamento || 'Não informado',
    elt.nomeFuncionario || 'Não informado',
    elt.responsavelEntrega || 'Não informado',
    elt.produto || 'Não informado',
    elt.justificativaRetirada || 'Não informado',
    elt.dataRetirada || 'Não informado',
  ]);

  headerReport(doc, empresa, "RELATÓRIO DE RETIRADAS EXTRAS", 235);

  addLineDivider(doc, marginLeft, 100);

  doc.setFontSize(15);
  doc.setTextColor(0, 0, 0);

  const tableContent = {
    head: headers,
    body: formattedData,
    startY: 110,
    theme: 'grid',
    fillColor: [255, 0, 0],
    headStyles: {
      fillColor: [30, 81, 57]
    },
    didDrawPage: function (data) {
      addFooter(doc, data, user);
    }
  };

  doc.autoTable(tableContent);
  doc.save(`relatorio_retiradas_extras_${empresa.nomeFantasia}_${dayjs().format('YYYY-MM-DD_HHmm')}.pdf`);
}

export function exportRetiradaPorFuncToPDF(dataToExport) {
  const tableData = dataToExport.tableData
  const empresa = dataToExport.selectEmpresa
  const user = dataToExport.dadosUsuario.pessoa;
  const colaborador = dataToExport.colaborador;

  const unit = "pt";
  const size = "A4";
  const orientation = "landscape";
  const marginSecondColumn = 440;

  const doc = new jsPDF(orientation, unit, size);

  const headers = [["Produto", "Empresa", "Status Empresa", "Setor", "Área", "Ponto Entrega", "Data Retirada"]];
  const formattedData = tableData.map(elt => [
    elt.produto || 'Não informado',
    elt.nomeEmpresa || 'Não informado',
    elt.statusEmpresa || 'Não informado',
    elt.nomeSetor || 'Não informado',
    elt.nomeArea || 'Não informado',
    elt.nomePontoEntrega || 'Não informado',
    elt.dataRetirada || 'Não informado',
  ]);

  headerReport(doc, empresa, "RELATÓRIO DE RETIRADAS FUNCIONÁRIO", 280);

  // DADOS DO COLABORADOR (FICHA EPI)
  const nomeColaborador = colaborador?.nome || 'Não informado';
  const matriculaColaborador = colaborador?.matricula || 'Não informada';
  const funcaoColaborador = colaborador?.funcao?.nome || 'Não informada';
  const setorColaborador = colaborador?.setor?.nome || 'Não informado';

  doc.setFont('helvetica', 'bold');
  doc.text("Dados do Funcionário:", marginLeft, 112);
  doc.setFont('helvetica', 'normal');

  addLineDivider(doc, marginLeft, 124);

  doc.setFont('helvetica', 'bold');
  doc.text("Nome:", marginLeft, 142);
  doc.setFont('helvetica', 'normal');
  doc.text(nomeColaborador, marginLeft + 35, 142);

  doc.setFont('helvetica', 'bold');
  doc.text("Matrícula:", marginLeft + marginSecondColumn, 142);
  doc.setFont('helvetica', 'normal');
  doc.text(matriculaColaborador, marginLeft + marginSecondColumn + 50, 142);

  doc.setFont('helvetica', 'bold');
  doc.text("Função:", marginLeft, 162);
  doc.setFont('helvetica', 'normal');
  doc.text(funcaoColaborador, marginLeft + 45, 162);

  doc.setFont('helvetica', 'bold');
  doc.text("Setor:", marginLeft + marginSecondColumn, 162);
  doc.setFont('helvetica', 'normal');
  doc.text(setorColaborador, marginLeft + marginSecondColumn + 33, 162);

  addLineDivider(doc, marginLeft, 174);

  doc.setFontSize(15);
  doc.setTextColor(0, 0, 0);

  const tableContent = {
    head: headers,
    body: formattedData,
    startY: 194,
    theme: 'grid',
    fillColor: [255, 0, 0],
    headStyles: {
      fillColor: [30, 81, 57]
    },
    didDrawPage: function (data) {
      addFooter(doc, data, user);
    }
  };

  doc.autoTable(tableContent);
  doc.save(`relatorio_retiradas_funcionario_${nomeColaborador}_${dayjs().format('YYYY-MM-DD_HHmm')}.pdf`);
}

export function exportSaidasProdutosToPDF(dataToExport) {
  const tableData = dataToExport.tableData
  const empresa = dataToExport.selectEmpresa
  const user = dataToExport.dadosUsuario.pessoa;
  const isDaily = dataToExport.isDaily;

  const unit = "pt";
  const size = "A4";
  const orientation = "landscape";

  const doc = new jsPDF(orientation, unit, size);

  const headers = isDaily ? [["Produto", "CA", "Quantidade", "Data"]] : [["Produto", "CA", "Quantidade", "Saídas"]];
  const formattedData = isDaily
    ? tableData.map(elt => [
      elt.nomeProduto || 'Não informado',
      elt.caProduto || 'Não informado',
      elt.quantidade || 'Não informado',
      elt.dataSaida || 'Não informado',
    ])
    : tableData.map(elt => [
      elt.nomeProduto || 'Não informado',
      elt.caProduto || 'Não informado',
      elt.quantidade || 'Não informado',
      elt.saidas || 'Não informado',
    ]);

  headerReport(doc, empresa, "RELATÓRIO DE SAÍDAS DE PRODUTOS", 280);

  addLineDivider(doc, marginLeft, 110);

  doc.setFontSize(15);
  doc.setTextColor(0, 0, 0);

  const tableContent = {
    head: headers,
    body: formattedData,
    startY: 130,
    theme: 'grid',
    fillColor: [255, 0, 0],
    headStyles: {
      fillColor: [30, 81, 57]
    },
    didDrawPage: function (data) {
      addFooter(doc, data, user);
    }
  };

  doc.autoTable(tableContent);
  doc.save(`relatorio_saidas_produtos_${dayjs().format('YYYY-MM-DD_HHmm')}.pdf`);
}

export function exportSaidasProdutosPorFuncToPDF(dataToExport) {
  const tableData = dataToExport.tableData;
  const empresa = dataToExport.selectedEmpresa;
  const user = dataToExport.dadosUsuario.pessoa;
  const isDaily = dataToExport.isDaily;
  const dataInicio = dataToExport.dataInicio;
  const dataFim = dataToExport.dataFim;

  const unit = "pt";
  const size = "A4";
  const orientation = "landscape";
  const marginLeft = 40;
  const marginSecondColumn = 440;

  const doc = new jsPDF(orientation, unit, size);

  const headers = isDaily ? [["Produto", "CA", "Quantidade", "Data Entrega"]] : [["Produto", "CA", "Quantidade", "Saídas"]];

  headerReport(doc, empresa, "RELATÓRIO DE SAÍDAS DE PRODUTOS POR RESPONSÁVEL", 380);

  // percorrer cada responsável
  tableData.forEach((responsavel, index) => {
    const isFirst = index === 0;

    if (!isFirst) {
      doc.addPage();
    }

    const startY = isFirst ? 110 : 30;

    const nomeResponsavel = responsavel.nomeResponsavel || 'Não informado';
    const matriculaResponsavel = responsavel.matriculaResponsavel || 'Não informado';
    const nomeFuncaoResponsavel = responsavel.nomeFuncaoResponsavel || 'Não informado';
    const tituloSetorResponsavel = responsavel.tituloSetorResponsavel || 'Não informado';
    const quantidadeTotalResponsavel = responsavel.quantidadeTotalResponsavel || 'Não informado';

    addLineDivider(doc, marginLeft, startY);

    // Dados do Responsavel
    doc.setFont('helvetica', 'bold');
    doc.text(`Responsável: ${nomeResponsavel}`, marginLeft, startY + 20);
    doc.setFont('helvetica', 'normal');

    doc.setFont('helvetica', 'bold');
    doc.text("Matrícula:", marginLeft + marginSecondColumn, startY + 20);
    doc.setFont('helvetica', 'normal');
    doc.text(matriculaResponsavel.toString(), marginLeft + marginSecondColumn + 50, startY + 20);

    doc.setFont('helvetica', 'bold');
    doc.text("Função:", marginLeft, startY + 40);
    doc.setFont('helvetica', 'normal');
    doc.text(nomeFuncaoResponsavel, marginLeft + 45, startY + 40);

    doc.setFont('helvetica', 'bold');
    doc.text("Setor:", marginLeft + marginSecondColumn, startY + 40);
    doc.setFont('helvetica', 'normal');
    doc.text(tituloSetorResponsavel, marginLeft + marginSecondColumn + 35, startY + 40);

    doc.setFont('helvetica', 'bold');
    doc.text("Quantidade Total de Produtos Entregue:", marginLeft, startY + 60);
    doc.setFont('helvetica', 'normal');
    doc.text(quantidadeTotalResponsavel.toString(), marginLeft + 200, startY + 60);

    doc.setFont('helvetica', 'bold');
    doc.text('Período Analisado:', marginLeft + marginSecondColumn, startY + 60);
    doc.setFont('helvetica', 'normal');
    doc.text(`${dataInicio} - ${dataFim}`, marginLeft + marginSecondColumn + 95, startY + 60);

    addLineDivider(doc, marginLeft, startY + 70);

    const formattedData = isDaily
      ? responsavel.produtos.map(produto => [
        produto.nomeProduto || 'Não informado',
        produto.caProduto || 'Não informado',
        produto.quantidadeTotal || 'Não informado',
        produto.dataSaida || 'Não informado',
      ])
      : responsavel.produtos.map(produto => [
        produto.nomeProduto || 'Não informado',
        produto.caProduto || 'Não informado',
        produto.quantidadeTotal || 'Não informado',
        produto.numeroSaidas || 'Não informado',
      ]);

    const tableContent = {
      head: headers,
      body: formattedData,
      startY: startY + 80,
      theme: 'grid',
      headStyles: {
        fillColor: [30, 81, 57]
      },
      didDrawPage: function (data) {
        // levar em consideração do data.pageNumber o index do responsável, mas também caso a tabela do responsavel atual tenha mais de uma pagina
        // get how much pages the table will have and the actual page
        const pageNumber = doc.internal.getCurrentPageInfo().pageNumber;
        data.pageNumber = pageNumber;
        addFooter(doc, data, user);
      }
    };

    doc.autoTable(tableContent);
  });

  doc.save(`relatorio_saidas_produtos_responsavel_${dayjs().format('YYYY-MM-DD_HHmm')}.pdf`);
}

export function exportToExcel(reportName, columns, apiRef) {
  const fileType = "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8";
  const fileExtension = ".xlsx";

  const rowModels = apiRef.current.getRowModels();
  const rows = Array.from(rowModels.values());

  // Convert data with headers to sheet
  const dataWithHeaders = rows.map((row) => {
    const obj = {};
    columns
      .filter((column) => column.headerName)
      .forEach((column) => {
        if (column.headerName) obj[column.headerName] = row[column.field];
      });
    return obj;
  });
  // Convert data with headers to sheet
  const ws = XLSX.utils.json_to_sheet(dataWithHeaders);

  // Create workbook and add sheet with data
  const wb = { Sheets: { data: ws }, SheetNames: ["data"] };

  // Write workbook to buffer in xlsx format
  const excelBuffer = XLSX.write(wb, { bookType: "xlsx", type: "array" });

  // Convert buffer to Blob with specified MIME type
  const data = new Blob([excelBuffer], { type: fileType });

  // Save data as a file with specified filename
  FileSaver.saveAs(data, `${reportName}_${dayjs().format('YYYY-MM-DD_HHmm')}` + fileExtension);
};

export const getIconAuthType = (tipo) => {
  switch (tipo) {
    case 'biometria':
      return <ClientManagementIcon fontSize='small' color='primary' />;
    case 'senha':
      return <GoodPincodeIcon fontSize='small' color='primary' />;
    case 'cracha':
      return <NameTagIcon fontSize='small' color='primary' />;
    default:
      return <Info fontSize='small' color='primary' />;
  }
};

export const getImageSrc = (photo) => {
  // ensure the image data URL has the correct prefix
  const prefix = "data:image/jpeg;base64,";
  return photo.startsWith(prefix) ? photo : `${prefix}${photo}`;
};

export const downloadBase64AsJpg = (event, photoUrl, fileName) => {
  event.preventDefault();
  const anchor = document.createElement('a');
  anchor.href = photoUrl;
  anchor.download = `${fileName}_captura.jpg`;
  document.body.appendChild(anchor);
  anchor.click();
  document.body.removeChild(anchor);
};