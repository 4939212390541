import { useEffect, useState, useRef, useCallback } from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { Controller, useForm } from 'react-hook-form';
import {
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    FormControl,
    Box,
    DialogContentText,
    Grid,
    TextField,
    Typography,
    Autocomplete,
    MenuItem,
    FormHelperText,
    Select,
    InputLabel,
    CircularProgress,
    Alert,
    InputAdornment,
    IconButton,
} from '@mui/material';
import { Notifications, NotificationsOff } from '@mui/icons-material';
import { useCommonItems } from '../../../../contexts/CommonItensProvider';
import SelectionCard from '../../../common/SelectionCard';
import GHEProduct from '../../../common/GHEProduct';
import { listaEmpresas } from '../../management/companies/configs/functions';
import { listaCategoriasEmpresa, listaProdutosCategoria } from '../products/configs/functions';
import { listaPermissoes, manipulaGHEs } from './config/functions';
import { getFormErrorMessage } from '../../../../configs/functions';

function CardList({ getValues, selectedCard, cards, handleSelectCard, handleRemoveCard }) {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                height: '480px',
                borderRadius: '8px',
                borderStyle: 'solid',
                borderWidth: 1,
                borderColor: '#cdcdcd',
                px: 1,
                overflowY: 'auto',
                '& > *': {
                    marginTop: 1,
                    marginBottom: 1,
                },
            }}
        >
            {cards && cards?.length ?
                <Grid container
                    direction="row"
                    justifyContent="space-around"
                    alignItems="stretch" spacing={2}
                    sx={{ mt: 0.1 }} >
                    {cards.map((card, index) => (
                        <Grid item key={card.id} xs={12}>
                            <SelectionCard
                                id={card.id}
                                title={card.nome}
                                description={card.descricao}
                                isSelected={selectedCard === card.id}
                                onClick={() => handleSelectCard(card)}
                                onRemove={() => handleRemoveCard(card)}
                            />
                            <input
                                type="hidden"
                                name={`categorias[${card.id}]`}
                                value={getValues(`categorias[${card.id}]`)}
                            />
                        </Grid>
                    ))}
                </Grid> :
                <Typography variant='body2' sx={{ mt: 1 }}>Escolha uma categoria no campo acima.</Typography>
            }
        </Box >
    );
}

function StepCategoriaEProdutos({ control, register, errors, setValue, getValues, watch, selectedCard, cards, selectedCardInfo }) {
    const [maxCategoriaPontoAlerta, setMaxCategoriaPontoAlerta] = useState(1)
    const [campoAtivado, setCampoAtivado] = useState(false);

    //TODO pegar os valores aqui da categoria e passar para os produtos como parametros
    const periodoGlobal = watch(`categorias[${selectedCard}].periodo`);
    const quantidadeMaximaGlobal = watch(`categorias[${selectedCard}].quantidade_maxima`);
    const alertaGlobal = watch(`categorias[${selectedCard}].ponto_alerta`);

    useEffect(() => {
        if (maxCategoriaPontoAlerta <= getValues(`categorias[${selectedCard}].ponto_alerta`)) {
            setValue(`categorias[${selectedCard}].ponto_alerta`, maxCategoriaPontoAlerta - 1)
        }
    }, [maxCategoriaPontoAlerta]);

    return (
        <>
            {cards.length > 0 && cards ?
                !selectedCard ? <Typography variant='body2'>Escolha uma categoria no(s) Card(s) ao lado. </Typography>
                    :
                    <>
                        <Typography variant='h2'>{selectedCardInfo?.nome}</Typography>
                        <Typography variant='body2' sx={{ my: 1 }}>Preencha os campos abaixos com as informações comuns para esta categoria neste GHE.</Typography>
                        <Grid
                            container
                            direction="row"
                            justifyContent="center"
                            alignItems="stretch"
                            spacing={2}
                        >
                            <Grid item xs={12} md={3}>
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    margin="dense"
                                    size='small'
                                    label="Quantidade"
                                    type='number'
                                    required
                                    name={`categorias[${selectedCard}].quantidade_maxima`}
                                    autoComplete='off'
                                    error={errors[`categorias[${selectedCard}].quantidade_maxima`] && true}
                                    helperText={errors[`categorias[${selectedCard}].quantidade_maxima`] ? 'A Quantidade é obrigatória.' : 'Quantidade de EPIs que podem ser usados/entregues.'}
                                    fullWidth
                                    variant="outlined"
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setValue(`categorias[${selectedCard}].quantidade_maxima`, value);
                                    }}
                                    inputProps={{ min: 1 }}
                                    value={watch(`categorias[${selectedCard}].quantidade_maxima`)}
                                />
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    margin="dense"
                                    size='small'
                                    label="Período"
                                    type='number'
                                    required
                                    name={`categorias[${selectedCard}].periodo`}
                                    autoComplete='off'
                                    error={errors[`categorias[${selectedCard}].periodo`] && true}
                                    helperText={errors[`categorias[${selectedCard}].periodo`] ? 'O Período é obrigatório.' : 'Período em dias que os EPIs podem ser usados/entregues.'}
                                    fullWidth
                                    variant="outlined"
                                    onChange={(e) => {
                                        setMaxCategoriaPontoAlerta(e.target.value)
                                        setValue(`categorias[${selectedCard}].periodo`, e.target.value)

                                    }}
                                    inputProps={{ min: 1 }}
                                    value={watch(`categorias[${selectedCard}].periodo`)}
                                />
                            </Grid>

                            <Grid item xs={12} md={3}>
                                <TextField
                                    InputLabelProps={{ shrink: true }}
                                    disabled={!campoAtivado}
                                    fullWidth
                                    margin="dense"
                                    size="small"
                                    label="Ponto de Alerta"
                                    // defaultValue={0}
                                    type="number"
                                    required
                                    name={`categorias[${selectedCard}].ponto_alerta`}
                                    autoComplete="off"
                                    variant="outlined"
                                    inputProps={{ min: 1, max: (maxCategoriaPontoAlerta - 1) }} // Define os valores mínimo e máximo
                                    onChange={(e) => {
                                        setValue(`categorias[${selectedCard}].ponto_alerta`, e.target.value)
                                    }}
                                    InputProps={{
                                        startAdornment: (
                                            <InputAdornment position="start">
                                                <IconButton
                                                    aria-label="toggle campo visibility"
                                                    onClick={() => setCampoAtivado((atual) => {
                                                        atual ? setValue(`categorias[${selectedCard}].ponto_alerta`, 0) : setValue(`categorias[${selectedCard}].ponto_alerta`, 1);
                                                        return !atual
                                                    })
                                                    }
                                                    edge="start"
                                                >
                                                    {campoAtivado ? <Notifications /> : <NotificationsOff />} {/* Ícone de cadeado aberto ou fechado */}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    value={watch(`categorias[${selectedCard}].ponto_alerta`)}
                                />
                            </Grid>
                        </Grid>
                        <Divider variant='middle' />
                        <Typography variant='h3'>Produtos</Typography>
                        <Typography variant='body2'>Abaixo estão listados os EPIs que fazem parte da categoria selecionada. Ao alterar as informações individuais dos EPIs, estas informações irão sobrepor as comuns do grupo.</Typography>
                        <Divider variant='middle' />
                        {selectedCardInfo?.produtos ?
                            (selectedCardInfo?.produtos).map((produto, index) =>
                                <GHEProduct
                                    key={produto.id}
                                    control={control}
                                    register={register}
                                    errors={errors}
                                    setValue={setValue}
                                    getValues={getValues}
                                    index={index}
                                    produto={produto}
                                    watch={watch}
                                    //TODO verificar se as variaveis passaram certo
                                    globalPeriod={periodoGlobal}
                                    globalAlert={alertaGlobal}
                                    globalQuantity={quantidadeMaximaGlobal}
                                />
                            )
                            : <Typography><CircularProgress /> Carregando...</Typography>}

                    </> :
                getValues('id_empresa') && <Typography variant='body2'>Adicione e escolha uma categoria ao lado.</Typography>

            }
        </>
    )
}

function GHEForm({ open, setOpen, selectedGHE, queryKey }) {
    const queryClient = useQueryClient();
    const { exibirAlerta, exibirDialog, handleCloseDialog } = useCommonItems();

    const isEditAction = !!selectedGHE;

    const {
        register,
        unregister,
        handleSubmit,
        getValues,
        setValue,
        watch,
        control,
        formState: { errors },
        reset,
    } = useForm();

    const [cards, setCards] = useState([]);
    const [existingCards, setExistingCards] = useState([]);
    const [selectedCard, setSelectedCard] = useState(null);
    const [categoriasEProdutos, setCategoriasEProdutos] = useState([]);
    const [permissoes, setPermissoes] = useState([]);
    const empresas = useRef([]);
    const [selectedCardInfo, setSelectedCardInfo] = useState([]);
    const [alreadyLoadInitialCards, setAlreadyLoadInitialCards] = useState(false);

    const empresasCarregadas = useRef(false);

    const carregarEmpresas = useCallback(async () => {
        try {
            exibirDialog(<Typography><CircularProgress size={20} />Buscando empresas...</Typography>);
            const res = await listaEmpresas();
            empresas.current = res.data.data;
            empresasCarregadas.current = true;
        } catch (error) {
            console.log('Erro ao carregar empresas:', error);
        } finally {
            handleCloseDialog();
        }
    }, []);

    async function loadCompanyData(GHEId) {
        await carregaCategorias();
        await carregaPermissoesByGrupoId(GHEId);
    }

    const carregaCategorias = async () => {
        exibirDialog(<Typography><CircularProgress size={20} /> Buscando categorias e produtos para a empresa selecionada...</Typography>);
        const idEmpresa = getValues('id_empresa');

        try {
            let categorias = [];
            const res = await listaCategoriasEmpresa(idEmpresa);
            categorias = res.data;

            // Array para armazenar as chamadas assíncronas
            const requests = categorias.map(async (categoria) => {
                try {
                    const prod = await listaProdutosCategoria(categoria.id);
                    categoria.produtos = (prod.data);
                } catch (error) {
                    console.error('Erro ao carregar produtos da categoria ' + categoria.id + ': ' + error);
                }
            });

            // Aguarda todas as solicitações serem concluídas
            await Promise.all(requests);
            setCategoriasEProdutos(categorias);
        } catch (error) {
            console.error('Erro ao carregar categorias: ' + error);
        } finally {
            handleCloseDialog();
        }
    }

    async function carregaPermissoesByGrupoId(id_grupo) {
        try {
            const response = await listaPermissoes({ id_grupo });
            // response.data.data.forEach((permissao) => {
            //     permissao.is_dirty = false;
            // });
            setPermissoes(response.data.data);
        } catch (error) {
            exibirAlerta('Erro ao carregar as Permissões', '', 'error');
        }
    }

    function createCardsInEdit() {
        if (alreadyLoadInitialCards) {
            return;
        }

        try {
            // buscar categorias selecionadas, a partir das permissões
            const selectedCategories = categoriasEProdutos.filter(categoria => {
                return permissoes.find(permissao => permissao.categoria.id === categoria.id);
            });

            setCards(selectedCategories);
            setExistingCards(selectedCategories);

            // apos listar categorias selecionadas, setar os default values para os campos
            // e setar os valores dos produtos
            // setar os valores dos produtos
            selectedCategories.forEach(categoria => {
                register(`categorias[${categoria.id}].id_categoria`, { value: categoria.id });
                setValue(`categorias[${categoria.id}].quantidade_maxima`, categoria.quantidade_maxima);
                setValue(`categorias[${categoria.id}].periodo`, categoria.periodo);
                setValue(`categorias[${categoria.id}].ponto_alerta`, categoria.ponto_alerta);

                //registra os campos dos produtos baseado em permissões produto.id
                categoria.produtos.forEach(produto => {
                    const permissao = permissoes.find(permissao => permissao.produto.id === produto.id);
                    const overwriteCategoryValues = !permissao?.infoCategoria;

                    if (!permissao) {
                        return;
                    }

                    register(`permissoes[${produto.id}].id_permissao`, { value: permissao.id });
                    register(`permissoes[${produto.id}].id_produto`, { value: produto.id });
                    register(`permissoes[${produto.id}].sobrescreverCategoria`, { value: overwriteCategoryValues });
                    register(`permissoes[${produto.id}].status`, { value: permissao.status });

                    if (permissao && overwriteCategoryValues) {
                        register(`permissoes[${produto.id}].periodo`, { value: permissao.periodo });
                        register(`permissoes[${produto.id}].ponto_alerta`, { value: permissao.pontoAlerta });
                        register(`permissoes[${produto.id}].quantidade_maxima`, { value: permissao.quantidadeMaxima });
                    } else {
                        register(`permissoes[${produto.id}].periodo`, { value: categoria.periodo });
                        register(`permissoes[${produto.id}].ponto_alerta`, { value: categoria.ponto_alerta });
                        register(`permissoes[${produto.id}].quantidade_maxima`, { value: categoria.quantidade_maxima });
                    }
                });
            });
        } catch (error) {
            console.log('Erro ao carregar categorias e produtos:', error);
        } finally {
            setAlreadyLoadInitialCards(true);
        }
    }

    const onSubmit = async (data) => {
        // limpa os arrys vazio ou null, ja que hookform cria isso sozinho
        if (data.permissoes) {
            data.permissoes = data.permissoes.filter(permissao => permissao != null);
        }
        if (data.categorias) {
            data.categorias = data.categorias.filter(categoria => categoria != null);
        }

        if (!isEditAction) {
            data.status = true;
        }

        try {
            exibirDialog('Realizando operação. Aguarde...');
            await manipulaGHEs(data, isEditAction);
            setOpen(false);
            exibirAlerta('Sucesso', `Grupo ${isEditAction ? 'editado' : 'cadastrado'} com sucesso.`, 'success');
            queryClient.invalidateQueries({ queryKey });
        } catch (error) {
            console.log('Erro ao salvar GHE:', error);
            exibirAlerta('Erro', `Erro ao ${isEditAction ? 'editar' : 'cadastrar'} o Grupo.`, 'error');
        } finally {
            handleCloseDialog();
        }
    };

    useEffect(() => {
        if (open) {
            if (!empresasCarregadas.current) {
                carregarEmpresas();
            }

            // set default values to form
            const defaultValues = {
                id_grupo: selectedGHE?.id ?? '',
                nome: selectedGHE?.nome ?? '',
                status: selectedGHE?.status ?? true,
                descricao: selectedGHE?.descricao ?? '',
                observacao: selectedGHE?.observacao ?? '',
                id_empresa: selectedGHE?.id_empresa ?? '',
                cod_externo: selectedGHE?.cod_externo ?? '',
            };

            reset(defaultValues);

            if (isEditAction) {
                setCards([]);
                loadCompanyData(selectedGHE.id);
            }
        } else {
            // limpa o formulário ao sair
            reset();
            setCards([]);
            setSelectedCard(null);
            setCategoriasEProdutos([]);
            setPermissoes([]);
            setSelectedCardInfo([]);
            setAlreadyLoadInitialCards(false);
        }
    }, [open]);

    useEffect(() => {
        if (isEditAction && categoriasEProdutos.length > 0 && permissoes.length > 0) {
            createCardsInEdit();
        }
    }, [categoriasEProdutos, permissoes]);

    useEffect(() => {
        if (open && isEditAction) {
            if (!alreadyLoadInitialCards) {
                exibirDialog('Carregando permissões...');
            } else {
                handleCloseDialog();
            }
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [alreadyLoadInitialCards, isEditAction, open]);

    return (
        <Dialog open={open} fullWidth maxWidth={'xl'}>
            <form onSubmit={handleSubmit(onSubmit)}>
                <>
                    {isEditAction
                        ? <DialogTitle>Editando GHE: <strong>{selectedGHE.nome}</strong></DialogTitle>
                        : <DialogTitle>Cadastrar novo GHE</DialogTitle>
                    }

                    <DialogContent sx={{ minHeight: '65vh' }}>
                        <DialogContentText sx={{ mb: 2 }}>
                            {isEditAction
                                ? 'Preencha corretamente os dados.'
                                : 'Preencha corretamente os dados abaixo para cadastrar um novo Grupo Homogêneo de Exposição.'}
                        </DialogContentText>

                        <Box sx={{ p: 2, backgroundColor: '#fafafa' }} >
                            <FormControl variant="standard" fullWidth >
                                <Grid
                                    container
                                    direction="row"
                                    justifyContent="center"
                                    alignItems="stretch"
                                    spacing={4}
                                >
                                    <Grid item xs={12} md={3}>
                                        <Typography variant='h3' component={'h3'}>Informações Gerais</Typography>

                                        <Typography p sx={{ my: 1 }}>
                                            Utilize os campos abaixo para inserir informações que facilitam a identificação do GHE.
                                        </Typography>

                                        <TextField
                                            InputLabelProps={{ shrink: true }}
                                            margin="dense"
                                            label="Nome"
                                            required
                                            placeholder="Digite o nome do GHE"
                                            name="nome"
                                            autoComplete='off'
                                            error={errors.nome && true}
                                            helperText={errors.nome && 'O Nome é obrigatório.'}
                                            fullWidth
                                            {...register('nome', { required: true })}
                                            variant="outlined"
                                        />

                                        <Controller
                                            name="descricao"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    InputLabelProps={{ shrink: true }}
                                                    margin="dense"
                                                    label="Descrição"
                                                    placeholder="Digite a Descrição"
                                                    name="descricao"
                                                    fullWidth
                                                    multiline
                                                    rows={2}
                                                />
                                            )}
                                        />

                                        <Controller
                                            name="observacao"
                                            control={control}
                                            defaultValue=""
                                            render={({ field }) => (
                                                <TextField
                                                    {...field}
                                                    InputLabelProps={{ shrink: true }}
                                                    margin="dense"
                                                    label="Observação"
                                                    placeholder="Digite a Observação"
                                                    name="observacao"
                                                    fullWidth
                                                    multiline
                                                    rows={2}
                                                />
                                            )}
                                        />

                                        <Grid item xs={12}>
                                            <TextField
                                                InputLabelProps={{ shrink: true }}
                                                margin='dense'
                                                label='Código Externo'
                                                placeholder='Digite o código externo'
                                                name='cod_externo'
                                                fullWidth
                                                {...register("cod_externo", { required: false })}
                                                error={!!errors.cod_externo}
                                                helperText={getFormErrorMessage(errors, 'cod_externo')}
                                                variant="outlined"
                                            />
                                        </Grid>

                                        {
                                            //campo empresa
                                            !!empresas.current ?
                                                <><FormControl fullWidth margin="dense">
                                                    <InputLabel id="empresa-select-label">Empresa</InputLabel>
                                                    <Controller
                                                        name="id_empresa"
                                                        control={control}
                                                        rules={{ required: 'O campo Empresa é obrigatório' }}
                                                        render={({ field }) => (
                                                            <Select
                                                                labelId="empresa-select-label"
                                                                label="Empresa"
                                                                {...field}
                                                                error={!!errors.id_empresa}
                                                                onChange={(e) => {
                                                                    field.onChange(e); // Isso garante que o valor seja atualizado internamente pelo react-hook-form
                                                                    const selectedEmpresaId = e.target.value; // Obtém o valor selecionado do evento
                                                                    setValue('id_empresa', selectedEmpresaId); // Atualiza o valor do campo id_empresa
                                                                    carregaCategorias();
                                                                    setCards([]);
                                                                    setValue('listaCategorias', null)
                                                                }}
                                                            >
                                                                {empresas.current.map((empresa) => (
                                                                    <MenuItem key={empresa.id} value={empresa.id}>
                                                                        {empresa.nomeFantasia}
                                                                    </MenuItem>
                                                                ))}
                                                            </Select>
                                                        )}
                                                    />
                                                    {errors.id_empresa && <FormHelperText>{errors.id_empresa.message}</FormHelperText>}
                                                </FormControl>
                                                </>
                                                :
                                                <>
                                                    <CircularProgress size={15} /> Carregando empresas...
                                                </>

                                        }
                                    </Grid>


                                    <Grid item xs={12} md={3}>
                                        {
                                            !getValues('id_empresa') ?
                                                <Alert severity='warning'>Escolha uma empresa no formulário ao lado para começar.</Alert> :
                                                <>
                                                    <Typography variant='h3' component={'h3'}>Categorias</Typography>
                                                    <Typography p sx={{ my: 1 }}> Selecione as categorias deste GHE.</Typography>
                                                    <Controller
                                                        name="listaCategorias"
                                                        control={control}
                                                        defaultValue={null}
                                                        render={({ field }) => (
                                                            <Autocomplete
                                                                {...field}
                                                                options={
                                                                    // filter cards from categoriasEProdutos, because it's already selected
                                                                    categoriasEProdutos.filter(categoria => !cards.find(card => card.id === categoria.id))
                                                                }
                                                                getOptionLabel={(option) => option.nome}
                                                                getOptionSelected={(option, value) => option.id === value?.id}
                                                                onBlur={() => setValue('listaCategorias', null)}
                                                                onChange={(_, newValue) => {
                                                                    if (newValue) {
                                                                        field.onChange(newValue); // Atualiza o valor do campo controlado com o novo valor selecionado

                                                                        //registra os campos dos produtos
                                                                        newValue?.produtos?.forEach(produto => {
                                                                            if (produto) {
                                                                                register(`permissoes[${produto.id}].id_produto`, { value: produto.id });
                                                                                register(`permissoes[${produto.id}].periodo`, { value: produto.periodo || newValue.periodo });
                                                                                register(`permissoes[${produto.id}].ponto_alerta`, { value: produto.ponto_alerta || newValue.ponto_alerta });
                                                                            }
                                                                        });


                                                                        const registerCategoria = (id, value) => {
                                                                            // Verifica se já existe uma entrada para esta categoria
                                                                            if (!getValues(`categorias[${id}]`)) {
                                                                                // Se não existir, registra uma nova entrada
                                                                                register(`categorias[${id}]`, { value });
                                                                            } else {
                                                                                // Se já existir, atualiza os valores da entrada existente
                                                                                setValue(`categorias[${id}]`, value);
                                                                            }
                                                                        };

                                                                        // Chame a função registerCategoria para registrar ou atualizar a categoria
                                                                        registerCategoria(newValue.id, {
                                                                            id_categoria: newValue.id,
                                                                            quantidade_maxima: newValue?.quantidade_maxima,
                                                                            periodo: newValue?.periodo,
                                                                            ponto_alerta: newValue?.ponto_alerta
                                                                        });

                                                                        setCards(prevState => {
                                                                            const updatedCards = [...prevState, newValue];
                                                                            return updatedCards;
                                                                        });
                                                                    }
                                                                }}
                                                                noOptionsText={
                                                                    categoriasEProdutos.length === 0 && cards.length === 0 ?
                                                                        <Alert severity='error'>A empresa selecionada não possui nenhuma Categoria cadastrada.</Alert>
                                                                        :
                                                                        cards.length === categoriasEProdutos.length ?
                                                                            <Alert severity='info'>Todas as categorias foram adicionadas.</Alert>
                                                                            : 'Nenhum item encontrado.'
                                                                }
                                                                renderInput={(params) => (
                                                                    <TextField
                                                                        {...params}
                                                                        label="Busca de Categoria"
                                                                        variant="outlined"
                                                                        helperText="Digite a categoria ou clique sob a lista"
                                                                    />
                                                                )}
                                                            />
                                                        )}
                                                    />

                                                    <Divider variant='middle' sx={{ my: 1 }} />

                                                    <Typography variant='h3' component={'h3'} sx={{ mb: 2 }}>Categorias Selecionadas:</Typography>

                                                    <CardList
                                                        getValues={getValues}
                                                        selectedCard={selectedCard}
                                                        cards={cards}
                                                        handleSelectCard={(card) => {
                                                            setSelectedCard(card.id);
                                                            setSelectedCardInfo(prevInfo => {
                                                                prevInfo = card;
                                                                return prevInfo;
                                                            });
                                                        }}
                                                        handleRemoveCard={(card) => {
                                                            const id = card.id

                                                            // verify if the card is some of original cards
                                                            // if true, set deletePermissions to true, else unregister the card

                                                            const isExistingCard = existingCards.find(card => card.id === id);

                                                            if (isExistingCard) {
                                                                // set deletePermissions to categorias.id
                                                                setValue(`categorias[${card.id}].deletePermissions`, true);
                                                            } else {
                                                                unregister(`categorias[${card.id}]`);
                                                            }

                                                            //registra os campos dos produtos
                                                            card?.produtos?.forEach(produto => {
                                                                unregister(`permissoes[${produto.id}]`);
                                                            });

                                                            // Remove o card da lista de cards
                                                            setCards(cards.filter(card => card.id !== id));
                                                            // Limpa a seleção se o card removido estiver selecionado
                                                            if (selectedCard === id) {
                                                                setSelectedCard(null);
                                                            }
                                                        }}
                                                    />
                                                </>}
                                    </Grid>

                                    <Grid item xs={12} md={6}>
                                        <Box
                                            sx={{
                                                height: '100%',
                                                borderRadius: '8px',
                                                borderStyle: 'solid',
                                                borderWidth: (getValues('id_empresa')) ? 1 : 0,
                                                borderColor: '#cdcdcd',
                                                p: 1.5,
                                                overflowY: 'auto',
                                                '& > *': {
                                                    marginTop: 1,
                                                    marginBottom: 1,
                                                },
                                            }}
                                        >
                                            <StepCategoriaEProdutos
                                                control={control}
                                                register={register}
                                                errors={errors}
                                                setValue={setValue}
                                                getValues={getValues}
                                                watch={watch}
                                                selectedCard={selectedCard}
                                                cards={cards}
                                                selectedCardInfo={selectedCardInfo}
                                            />
                                        </Box>
                                    </Grid>
                                </Grid>
                            </FormControl>
                        </Box>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => setOpen(false)} variant="contained">
                            Cancelar
                        </Button>

                        <Divider orientation="vertical" flexItem sx={{ mx: 1 }} />

                        <Button type="submit" variant="contained">
                            Salvar
                        </Button>
                    </DialogActions>
                </>
            </form>
        </Dialog>
    );
}

export default GHEForm;